import { fetchXMLData, User, XML_ACTION } from '@danfoss/etui-sm-xml';
import {
  HistoryArchiveFile,
  HistoryArchiveFileContent,
  HistoryArchiveFileData,
} from '../components/ConfigurationItemContentList/types';

export const fetchHistoryArchiveFileData = async ([
  historyArchiveFiles,
  user,
  url,
]: [HistoryArchiveFile[], User, string]): Promise<HistoryArchiveFileData> => {
  try {
    const historyArchiveFileContents: HistoryArchiveFileContent[] = [];
    const historyArchiveFileNames: string[] = [];
    await historyArchiveFiles.reduce(async (Promise, historyArchiveFile) => {
      await Promise;
      const historyArchiveFileContent: HistoryArchiveFileContent =
        await fetchHistoryArchiveFileContent([historyArchiveFile, user, url]);
      historyArchiveFileContents.push(historyArchiveFileContent);
      historyArchiveFileNames.push(historyArchiveFile._);
    }, Promise.resolve());
    const historyArchiveFileData: HistoryArchiveFileData = {
      historyArchiveFileContents,
      historyArchiveFileNames,
    };
    return historyArchiveFileData;
  } catch (e) {
    throw new Error();
  }
};

export const fetchHistoryArchiveFileContent = async ([
  historyArchiveFile,
  user,
  url,
]: [HistoryArchiveFile, User, string]): Promise<HistoryArchiveFileContent> => {
  const offset: string = '0';
  let b64: string = '';
  const { _: historyArchiveFileName, access_area } = historyArchiveFile;
  try {
    let fileContentResponse = await fetchHistoryArchiveFileContentSM([
      user,
      url,
      historyArchiveFileName,
      offset,
      access_area,
    ]);
    if (fileContentResponse?.error !== '0') {
      throw new Error();
    }
    b64 = fileContentResponse?.encodedfile?.b64;
    while (fileContentResponse?.done !== '1') {
      // eslint-disable-next-line no-await-in-loop
      fileContentResponse = await fetchHistoryArchiveFileContentSM([
        user,
        url,
        historyArchiveFileName,
        fileContentResponse?.offset[1].toString(),
        access_area,
      ]);
      b64 += fileContentResponse?.encodedfile?.b64;
    }
    const historyArchiveFileContent: HistoryArchiveFileContent = {
      b64,
      size: fileContentResponse?.enc_bytes,
      offset: fileContentResponse?.tot_bytes,
    };
    return historyArchiveFileContent;
  } catch (e) {
    throw new Error();
  }
};

export const fetchHistoryArchiveFileContentSM = async ([
  user,
  url,
  historyArchiveFileName,
  offset,
  access_area,
]: [User, string, string, string, string]) => {
  try {
    const data = await fetchXMLData({
      url,
      attributes: getAttributes([
        user,
        offset,
        historyArchiveFileName,
        access_area,
      ]),
    });
    return data;
  } catch (e) {
    throw new Error();
  }
};

export const getAttributes = ([
  user,
  offset,
  historyArchiveFileName,
  access_area,
]: [User, string, string, string]) => {
  return {
    user: user?.user,
    password: user?.password,
    offset,
    access_area,
    filename: historyArchiveFileName,
    storeview_only: '1',
    version: 'C',
    action: XML_ACTION.GET_FILE,
  };
};
