import * as React from 'react';
import { Div, Label } from '@danfoss/etui-system-elements';
import { ConfigurationProvider, TABLE_ADDRESS } from '../Configuration';
import { EditType } from '../Configuration/components/ConfigurationItemContentList/types';
import { UnitConfigurationBase } from './UnitConfigurationBase';
import { UnitConfigurationProps } from './UnitConfiguration.types';

const specialNodeOverviewItems = new Set([
  '0|{Node_Type}',
  '0|{RO8_Board}',
  '0|{OI8_Board}',
  '0|{SI8_Board}',
  '0|{VO2_Board}',
  '0|{VLT}',
  '0|{Utility_Meter}',
  '0|{Light_Panel}',
  '0|{Generic}',
  '0|AK-CM',
  '0|{Calculations}',
]);

const PASSWORD_PNUM = '4902';

function maskPassword(password: string): string {
  return ''.padStart(password.length, '*');
}

function UnitConfiguration({
  route = '/configuration',
  onPasswordUpdatedForCurrentUser,
}: UnitConfigurationProps) {
  const handlePasswordUpdatedForCurrentUser = (
    passwordUpdated: boolean,
    updatedPassword: string,
  ) => {
    onPasswordUpdatedForCurrentUser(passwordUpdated, updatedPassword);
  };
  return (
    <ConfigurationProvider
      route={route}
      itemByTabRenderers={{
        [TABLE_ADDRESS.SYSTEM_USERS]: (
          item,
          defaultRender,
          editControlRenderer,
          nameRenderer,
        ) =>
          item.pnum === PASSWORD_PNUM ? (
            <>
              <Label
                testId="unitConfiguration-systemUsers-label"
                style={{
                  paddingLeft: `${parseInt(item.ind, 10) * 20}px`,
                }}
              />
              {nameRenderer()}
              <Div flexGrow="1" />
              <Div width={['30%', '25%']}>
                {editControlRenderer({
                  item: { ...item, value: maskPassword(item.value) },
                  editType: EditType.Password,
                })}
              </Div>
            </>
          ) : (
            defaultRender
          ),
        [TABLE_ADDRESS.NODE_OVERVIEW]: (item, defaultRender) =>
          specialNodeOverviewItems.has(item.token) ? (
            <Label
              testId="unitConfiguration-nodeOverview-label"
              style={{
                paddingLeft: `${parseInt(item.ind, 10) * 20}px`,
                width: '100%',
              }}
            >
              <Div display="flex" justifyContent="flex-start">
                <Div minWidth="33.3%">{item.name}</Div>
                {item.value
                  .split(' ')
                  .filter(Boolean)
                  .map((v, i) => (
                    <Div minWidth="33.3%" key={i}>
                      {v}
                    </Div>
                  ))}
              </Div>
            </Label>
          ) : (
            defaultRender
          ),
      }}
    >
      <UnitConfigurationBase
        route={route}
        onPasswordUpdatedForCurrentUser={handlePasswordUpdatedForCurrentUser}
      />
    </ConfigurationProvider>
  );
}

export { UnitConfiguration };
