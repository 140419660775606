import { MxGraph, Rule } from '@danfoss/etui-scada';
import { danfossJsonToDrawIoXml } from '@danfoss/etui-scada/utils/danfoss-to-draw-io-xml';
import {
  Unit,
  UnitNetwork,
  User,
  XML_DEVICE_NODETYPE,
} from '@danfoss/etui-sm-xml';
import { getArray } from 'utils';
import { getSourceIdMeta } from '../actions/fetch-data-source-by-source-ids';
import {
  LocalGraphicsVz2DataPoints,
  LocalGraphicsMapping,
} from '../types/LocalGraphics.types';
import { convertB64ToByteArray } from './convertB64ToByteArray';
import { createImgSourceFromBytes } from './create-image-source-from-bytes';
import { getDataPointsFromUnits } from './migration-utils';
import { showBitMapImage } from './show-bit-map-image';

export const parseVz2File = (b64: string) => {
  const imageStartByte: number = 21;
  const reservedSpace: number = 32;
  const pointBytesNumber: number = 16 + reservedSpace;
  const newVz2DataPoints: LocalGraphicsVz2DataPoints[] = [];
  const actualB64ByteArray: Uint8Array = convertB64ToByteArray(b64);
  const pngSize =
    (actualB64ByteArray[0] << 24) +
    (actualB64ByteArray[1] << 16) +
    (actualB64ByteArray[2] << 8) +
    actualB64ByteArray[3];
  const pngImageByteArray = new Uint8Array(pngSize);
  for (let count = 0; count < pngSize; count++) {
    pngImageByteArray[count] = actualB64ByteArray[count + imageStartByte];
  }
  const datapointsOffset = imageStartByte + pngSize + 4 + 2;
  let totalDataPoints = 0;
  if (actualB64ByteArray.length > datapointsOffset) {
    totalDataPoints =
      (actualB64ByteArray[imageStartByte + pngSize + 4] << 8) +
      actualB64ByteArray[imageStartByte + pngSize + 4 + 1];
  }
  if (totalDataPoints > 0) {
    for (let index = 0; index < totalDataPoints; index++) {
      const pointOffset = datapointsOffset + index * pointBytesNumber;
      const params: LocalGraphicsVz2DataPoints = {
        name: '',
        iPointName: actualB64ByteArray[pointOffset + 0],
        iUnits: actualB64ByteArray[pointOffset + 1],
        iParameterName: actualB64ByteArray[pointOffset + 2],
        iSize: actualB64ByteArray[pointOffset + 3],
        nodetype: actualB64ByteArray[pointOffset + 4],
        node: actualB64ByteArray[pointOffset + 5],
        mod: actualB64ByteArray[pointOffset + 6],
        point: actualB64ByteArray[pointOffset + 7],
        parmIndex:
          (actualB64ByteArray[pointOffset + 8] << 8) +
          actualB64ByteArray[pointOffset + 9],
        x:
          (actualB64ByteArray[pointOffset + 10] << 8) +
          actualB64ByteArray[pointOffset + 11],
        y:
          (actualB64ByteArray[pointOffset + 12] << 8) +
          actualB64ByteArray[pointOffset + 13],
        host:
          (actualB64ByteArray[pointOffset + 14] << 8) +
          actualB64ByteArray[pointOffset + 15],
        generic_node: actualB64ByteArray[pointOffset + 14],
      };
      params.generic_node =
        params.nodetype === +XML_DEVICE_NODETYPE.NODETYPE_GN
          ? 2
          : actualB64ByteArray[pointOffset + 14];
      newVz2DataPoints.push(params);
    }
  }
  return {
    actualB64ByteArray,
    parsedImage: pngImageByteArray,
    parsedDatapoints: newVz2DataPoints,
  };
};

export const getXmlMappingFromParsedFile = async (
  parsedImage: Uint8Array,
  parsedDatapoints: any[],
  units: Unit[],
  user: User,
  getFirstValidUrl: (unit: Unit) => string,
  getFirstValidUnitNetwork: (unit: Unit) => UnitNetwork,
  isSM800A: boolean,
) => {
  const points = await getDataPointsFromUnits(
    units,
    user,
    getFirstValidUrl,
    getFirstValidUnitNetwork,
    parsedDatapoints,
  );
  const backgroundImage = isSM800A
    ? getPngBackgroundImage(parsedImage)
    : getBmpBackgroundImage(parsedImage);

  const xmlMapping = await danfossJsonToDrawIoXml(
    {
      value: getArray(points),
      backgroundImage,
    },
    true,
  );
  return xmlMapping;
};

export const getPngBackgroundImage = (parsedImage: Uint8Array) => {
  const { imageString: backgroundImage } = createImgSourceFromBytes(
    parsedImage,
    'png',
  );
  return backgroundImage;
};

export const getBmpBackgroundImage = (parsedImage: Uint8Array) => {
  const { imageString: backgroundImage } =
    showBitMapImage(parsedImage).bmpImageSource;
  return backgroundImage;
};

export const getRuleDataSourceIds = (rules: Rule[]) => {
  return rules?.map(rule => rule.dataSource.getSourceId()).filter(Boolean);
};

export const getRules = (
  mappings: LocalGraphicsMapping[],
  parsedDatapoints: LocalGraphicsVz2DataPoints[],
) => {
  const rules: Rule[] = [];
  mappings?.forEach((mapping, index) => {
    const defaultData = Rule.defaultData();
    const rule = new Rule(`${mapping.cellId}_${mapping.paramId}`, defaultData);
    rule.name = mapping.paramId;
    rule.dataSource.setSourceId(mapping.paramId);
    rule.dataSource.setParamIndex(parsedDatapoints[index].parmIndex);
    rule.mappings.addMapping().setShapeIdentifier(mapping.cellId);
    rule.display.parameterName = parsedDatapoints[index].iParameterName === 1;
    rule.display.assetName = parsedDatapoints[index].iPointName === 1;
    rule.display.unit = parsedDatapoints[index].iUnits === 1;
    rule.setStyles('fontSize', parsedDatapoints[index].iSize.toString());
    rules.push(rule);
  });
  return rules;
};

export const getDataPointsFromRulesAndGraph = (
  localGraphicsRules: Rule[],
  graph: MxGraph,
) => {
  const localGraphicsVz2DataPoints: LocalGraphicsVz2DataPoints[] = [];
  localGraphicsRules?.forEach(rule => {
    const { host, nodetype, node, mod, point } = getSourceIdMeta(
      rule.dataSource.sourceId,
    );
    const { x, y } = getAxisFromGraph(rule, graph);

    const dataPoints: LocalGraphicsVz2DataPoints = {
      name: '',
      host: Number(host),
      iPointName: rule.display.assetName === true ? 1 : 0,
      iUnits: rule.display.unit === true ? 1 : 0,
      iParameterName: rule.display.parameterName === true ? 1 : 0,
      iSize: Number(rule.styles.fontSize),
      nodetype: Number(nodetype),
      node: Number(node),
      mod: Number(mod),
      point: Number(point),
      parmIndex: rule.dataSource.paramIndex,
      x,
      y,
      generic_node: Number(node),
    };
    localGraphicsVz2DataPoints.push(dataPoints);
  });
  return localGraphicsVz2DataPoints;
};

const getAxisFromGraph = (rule: Rule, graph: MxGraph) => {
  const cellId = rule.mappings.mappings[0].shapeIdentifier;
  const cells: any[] = Object.values(graph?.getMxCells());
  const cell = cells?.find(cell => cell?.id === cellId);
  const { x, y } = cell?.geometry;
  return { x, y };
};

export const insertDataPointsVz2 = (
  localGraphicsActualB64Bytes: Uint8Array,
  localGraphicsVz2DataPoints: LocalGraphicsVz2DataPoints[],
) => {
  const imageStartByte: number = 21;
  let datapointsOffset = imageStartByte + 4;
  const reservedSpace = 32;

  const pngSize =
    (localGraphicsActualB64Bytes[0] << 24) +
    (localGraphicsActualB64Bytes[1] << 16) +
    (localGraphicsActualB64Bytes[2] << 8) +
    localGraphicsActualB64Bytes[3];
  datapointsOffset += pngSize;

  const imageArray = [].slice.call(localGraphicsActualB64Bytes);
  imageArray.splice(datapointsOffset, imageArray.length);
  imageArray.push((localGraphicsVz2DataPoints.length & 0xff00) >> 8);
  imageArray.push(localGraphicsVz2DataPoints.length & 0x00ff);
  if (localGraphicsVz2DataPoints.length) {
    for (let index = 0; index < localGraphicsVz2DataPoints.length; index++) {
      imageArray.push(localGraphicsVz2DataPoints[index].iPointName);
      imageArray.push(localGraphicsVz2DataPoints[index].iUnits);
      imageArray.push(localGraphicsVz2DataPoints[index].iParameterName);
      imageArray.push(localGraphicsVz2DataPoints[index].iSize);
      imageArray.push(localGraphicsVz2DataPoints[index].nodetype);
      imageArray.push(localGraphicsVz2DataPoints[index].node);
      imageArray.push(localGraphicsVz2DataPoints[index].mod);
      imageArray.push(localGraphicsVz2DataPoints[index].point);
      imageArray.push(
        (localGraphicsVz2DataPoints[index].parmIndex & 0xff00) >> 8,
      );
      imageArray.push(localGraphicsVz2DataPoints[index].parmIndex & 0x00ff);
      imageArray.push((localGraphicsVz2DataPoints[index].x & 0xff00) >> 8);
      imageArray.push(localGraphicsVz2DataPoints[index].x & 0x00ff);
      imageArray.push((localGraphicsVz2DataPoints[index].y & 0xff00) >> 8);
      imageArray.push(localGraphicsVz2DataPoints[index].y & 0x00ff);
      imageArray.push((localGraphicsVz2DataPoints[index].host & 0xff00) >> 8);
      imageArray.push(localGraphicsVz2DataPoints[index].host);
      for (
        let reservedSpaceIndex = 0;
        reservedSpaceIndex < reservedSpace;
        reservedSpaceIndex++
      ) {
        imageArray.push(0);
      }
    }
  } else {
    imageArray.push(0);
    imageArray.push(0);
  }
  const localGraphicsImageBytes = new Uint8Array(imageArray);
  return localGraphicsImageBytes;
};
