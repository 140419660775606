import * as React from 'react';
import { useTheme } from '@danfoss/etui-system';
import { Accordion, AccordionSection, Icon, icons } from '@danfoss/etui-core';
import { Div, P } from '@danfoss/etui-system-elements';
import { useTranslation } from 'react-i18next';
import {
  useUnit,
  SOFTWARE_VERSION_PREFIX,
  getIsMatchedSoftwareVersion,
  useApp,
  Protocol,
  useUnitConfiguration,
  useAuth,
} from '@danfoss/etui-sm';
import { IndiviualUnitInfo } from './IndiviualUnitInfo';
import { IndividualUnitMinimalInfo } from './IndividualUnitMinimalInfo';
import { UnitStatusInfo } from './UnitStatusInfo';
import { DataItem } from './DataItem';
import { UnitMinimalSoftwareInfo } from './UnitMinimalSoftwareInfo';
import { OfflineUnitInfo } from './OfflineUnitInfo/OfflineUnitInfo';
import { OfflineUnitError } from './OfflineUnitInfo';

function SvWInfoPage({ selectedUnit }) {
  const { t } = useTranslation();
  const [state] = useApp();
  const { units, failedNetworks, unitInfo, allNetworks } = useUnit();
  const theme = useTheme();
  const { user } = useAuth();
  const {
    publicIpInInternalUnit,
    connectedIpNotFound,
    macAddrSameForMasterSlaveUnits,
  } = useUnitConfiguration(units, unitInfo, state.xmlBackendIp, user);
  const isHttp = state.protocol === Protocol.http;

  const isUnitOffline = network => {
    const isFailedNetwork = failedNetworks.some(
      unit => unit.network.addr === network.addr,
    );
    return isFailedNetwork;
  };

  const isNetworkInvalid = currentNetwork => {
    const isFailedNetwork = unitInfo.invalidUnitNetworks.some(
      network => network.addr === currentNetwork.addr,
    );
    return isFailedNetwork;
  };

  const isMinimalDisplay = () => {
    return getIsMatchedSoftwareVersion(units, [SOFTWARE_VERSION_PREFIX.G08]);
  };

  const getCurrentUnit = network => {
    const validUnit = units.find(unit => unit.unit_addr === network.addr);
    return validUnit;
  };

  const data = selectedUnit
    ? allNetworks.filter(unit => unit.addr === selectedUnit.unit_addr)
    : allNetworks;

  return (
    <>
      <Accordion>
        <AccordionSection name={t('t3125')} defaultOpen={true}>
          <Div display="flex" flexDirection="row" ml={theme.spacing.xxs}>
            <P
              pr={theme.spacing.sm}
              fontWeight={theme.typography.fontWeightBold}
            >
              {t('t3826')}
            </P>
            <P>{t('t3523')}</P>
          </Div>
          <Div flex="1">
            <UnitMinimalSoftwareInfo
              currentUnit={units[0]}
              unitInfo={unitInfo}
              isConnectedIpNotFound={connectedIpNotFound}
            />
          </Div>
        </AccordionSection>
      </Accordion>
      {data.map(network => (
        <Accordion key={network.addr}>
          <AccordionSection
            key={`${t('t136')}${network.addr} ${network.name}`}
            name={`${t('t136')} ${network.addr}: ${network.name}`}
            defaultOpen={true}
          >
            {isMinimalDisplay() && !isUnitOffline(network) ? (
              <>
                <Div display="flex" flexDirection="row">
                  <Icon
                    size={24}
                    styles={{
                      root: {
                        color: theme.palette.success.main,
                        mr: `${theme.spacing.xs}px`,
                      },
                    }}
                    glyph={icons.CHECK_CIRCLE}
                  />
                  <Div flex="1">
                    <DataItem label={t('t564')} value=" " />
                  </Div>
                </Div>
                <Div flex="1">
                  <IndividualUnitMinimalInfo
                    currentUnit={getCurrentUnit(network)}
                    network={network}
                    isPublicIpInInternalUnit={publicIpInInternalUnit}
                    macAddrSameForMasterSlaveUnits={
                      macAddrSameForMasterSlaveUnits
                    }
                  />
                </Div>
              </>
            ) : isUnitOffline(network) ? (
              <>
                <Div display="flex" flexDirection="row" mb={theme.spacing.md}>
                  <Icon
                    size={24}
                    styles={{
                      root: {
                        color: theme.palette.error.main,
                        mr: `${theme.spacing.xs}px`,
                      },
                    }}
                    glyph={icons.ERROR_CIRCLE}
                  />
                  <Div flex="1">
                    <Div>
                      <OfflineUnitError
                        network={network}
                        isHttp={isHttp}
                        units={units}
                        unitInfo={unitInfo}
                      />
                    </Div>
                    <OfflineUnitInfo
                      network={network}
                      unitInfo={unitInfo}
                      isPublicIpInInternalUnit={publicIpInInternalUnit}
                    />
                  </Div>
                </Div>
              </>
            ) : isNetworkInvalid(network) ? (
              <>
                <Div display="flex" flexDirection="row" mb={theme.spacing.md}>
                  <Icon
                    size={24}
                    styles={{
                      root: {
                        color: theme.palette.warning.main,
                        mr: `${theme.spacing.xs}px`,
                      },
                    }}
                    glyph={icons.WARNING_CIRCLE}
                  />
                  <Div flex="1">
                    <DataItem label={t('t3124')} value=" " />
                  </Div>
                </Div>
                <Div flex="1">
                  <IndiviualUnitInfo
                    currentUnit={network}
                    network={network}
                    updateType="SPK"
                    showUpdateNotifications={true}
                    showUpdateNotificationText={true}
                    isPublicIpInInternalUnit={publicIpInInternalUnit}
                    macAddrSameForMasterSlaveUnits={
                      macAddrSameForMasterSlaveUnits
                    }
                  />
                </Div>
              </>
            ) : (
              <Div flex="1">
                <UnitStatusInfo
                  currentUnit={getCurrentUnit(network)}
                  updateType="SPK"
                  showUpdateNotifications={true}
                  showUpdateNotificationText={true}
                />
                <IndiviualUnitInfo
                  currentUnit={getCurrentUnit(network)}
                  network={network}
                  updateType="SPK"
                  showUpdateNotifications={true}
                  showUpdateNotificationText={true}
                  isPublicIpInInternalUnit={publicIpInInternalUnit}
                  macAddrSameForMasterSlaveUnits={
                    macAddrSameForMasterSlaveUnits
                  }
                />
              </Div>
            )}
          </AccordionSection>
        </Accordion>
      ))}
    </>
  );
}

export { SvWInfoPage };
