import { _ as _objectWithoutProperties, a as _objectSpread2 } from '../_rollupPluginBabelHelpers-6901a291.js';
import { buildXML, parseXMLToJSON } from '@danfoss/etui-sm-xml';
import { v4 } from 'uuid';

const _excluded = ["x", "y", "name"];
const mxCellStyle = 'rounded=1;whiteSpace=wrap;html=1;opacity=70;strokeColor=none';
const imageStyleStart = 'shape=image;verticalLabelPosition=bottom;labelBackgroundColor=#ffffff;verticalAlign=top;aspect=fixed;imageAspect=0;image=data:image/jpeg';
const imageStyleEnd = 'imageBackground=#ffffff;';
function getMxTopCells() {
  return [{
    tag: 'mxCell',
    attributes: {
      id: '0'
    }
  }, {
    tag: 'mxCell',
    attributes: {
      id: '1',
      parent: '0'
    }
  }];
}
function getImageDimensions(file) {
  const img = Buffer.from(file, 'base64');
  const sizeOf = require('image-size');
  const dimensions = sizeOf(img);
  return {
    h: dimensions.height.toString(),
    w: dimensions.width.toString()
  };
}
function getBackgroundImage(backgroundImage, width, height) {
  return [{
    tag: 'mxCell',
    attributes: {
      id: v4(),
      style: `${imageStyleStart},${backgroundImage};${imageStyleEnd}`,
      vertex: '1',
      parent: '1'
    },
    items: [{
      tag: 'mxGeometry',
      attributes: {
        x: '0',
        y: '0',
        width,
        height,
        as: 'geometry'
      }
    }]
  }];
}
function convertDanfossCellsToMxCells(danfossCells, isLocal) {
  const mxCells = danfossCells.map(_ref => {
    let {
        x,
        y,
        name
      } = _ref,
      attributes = _objectWithoutProperties(_ref, _excluded);
    return {
      tag: 'mxCell',
      attributes: _objectSpread2({
        style: mxCellStyle,
        vertex: '1',
        parent: '1',
        id: v4(),
        value: `${name ?? 'N/A'}`
      }, attributes),
      items: [{
        tag: 'mxGeometry',
        attributes: {
          x,
          y,
          width: isLocal ? '90' : '50',
          height: isLocal ? '40' : '25',
          as: 'geometry'
        }
      }]
    };
  });
  const mappings = mxCells.filter(_ref2 => {
    let {
      attributes: att
    } = _ref2;
    return att.paramId;
  }).map(_ref3 => {
    let {
      attributes: att
    } = _ref3;
    return {
      cellId: att.id,
      paramId: att.paramId
    };
  });
  return {
    mxCells,
    mappings
  };
}
async function danfossJsonToDrawIoXml(danfossXmlJson, isLocal) {
  const {
    value: danfossCells = [],
    backgroundImage
  } = danfossXmlJson || {};
  const {
    mxCells,
    mappings
  } = convertDanfossCellsToMxCells(danfossCells, isLocal);
  const {
    w,
    h
  } = backgroundImage ? getImageDimensions(backgroundImage) : {
    w: 0,
    h: 0
  };
  const xml = buildXML({
    // @ts-ignore
    tag: 'mxGraphModel',
    items: [{
      tag: 'root',
      items: backgroundImage ? [...getMxTopCells(), ...getBackgroundImage(backgroundImage, w, h), ...mxCells] : [...getMxTopCells(), ...mxCells]
    }]
  });
  return {
    xml,
    mappings
  };
}
async function danfossToDrawIoXml(danfossXml) {
  const {
    resp: danfossXmlJson
  } = await parseXMLToJSON(danfossXml);
  return danfossJsonToDrawIoXml(danfossXmlJson);
}

export { danfossJsonToDrawIoXml, danfossToDrawIoXml };
