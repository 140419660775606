import * as React from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import { ConfigurationListItem, Unit } from '@danfoss/etui-sm-xml';
import { ContainerDimensions } from '@danfoss/etui-core';
import { EditType } from 'pages/ConfigurationPage/Configuration/components/ConfigurationItemContentList/types';
import { SaveFunc } from '../../../../../Configuration/components/ConfigurationItemContentList/ConfigurationListItemEdit';
import {
  getEditType,
  getHtmlId,
  getIsEditableByItem,
} from '../../../../../Configuration/components/ConfigurationItemContentList/utils';
import { ConfigurationListItem as ListItem } from '../../../../../Configuration/components/ConfigurationItemContentList/ConfigurationListItem';

const ROW_HEIGHT_MIN = 42;
const ROW_HEIGHT_MAX = 50;
const getItemHasActionEditType = (item: ConfigurationListItem) =>
  getEditType(item) === EditType.Action;

const getItemRowHeight = (item: ConfigurationListItem) =>
  getItemHasActionEditType(item) ? ROW_HEIGHT_MAX : ROW_HEIGHT_MIN;

interface CopyItemSettingsVirtualizedListProps {
  isUpdating?: boolean;
  list: ConfigurationListItem[] | any;
  unit?: Unit;
  tabIsEditable: boolean;
  onSave?: SaveFunc;
  itemRenderer?: (...args: any[]) => React.ReactNode;
  group: string;
}

function CopyItemSettingsVirtualizedList({
  isUpdating = false,
  list,
  unit,
  tabIsEditable,
  onSave,
  itemRenderer,
  group,
}: CopyItemSettingsVirtualizedListProps) {
  const Row = ({ rowIndex, style, data = [] }): JSX.Element => {
    const item = data[rowIndex];
    const isLast = data.length === rowIndex + 1;
    const isItemEditable = tabIsEditable && getIsEditableByItem(item);
    return (
      <span style={style} id="span">
        <ListItem
          isSearchable={true}
          key={`${rowIndex}-${group}`}
          index={rowIndex}
          item={item}
          unit={unit}
          isLast={isLast}
          isEditable={isItemEditable}
          isAuthorized={true}
          isUpdating={isUpdating}
          htmlId={getHtmlId(item, +`${rowIndex}${group}`)}
          onSave={onSave}
          itemRenderer={itemRenderer}
          showTooltip={false}
        />
      </span>
    );
  };

  return (
    <ContainerDimensions>
      {({ width }) => (
        <Grid
          columnCount={1}
          columnWidth={() => '100%'}
          height={list?.length * ROW_HEIGHT_MIN + 5}
          rowCount={list?.length || 0}
          rowHeight={index => getItemRowHeight(list[index])}
          width={width}
          itemData={list}
          style={{ overflow: 'visible' }}
        >
          {Row}
        </Grid>
      )}
    </ContainerDimensions>
  );
}

export { CopyItemSettingsVirtualizedList };
