import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { FileContent } from 'pages/GraphicEditorPage/types/LocalGraphics.types';
import { convertB64ToByteArray } from 'pages/GraphicEditorPage/utils';
import { createBlobFromByteArray } from 'pages/GraphicEditorPage/utils/createBlobFromByteArray';

export const exportFilesAsZip = async (
  fileNames: string[],
  fileContents: FileContent[],
  zipFileName: string,
) => {
  try {
    const zip = new JSZip();
    fileContents?.forEach(async (graphicFileContent, index) => {
      const byteArray: Uint8Array = convertB64ToByteArray(
        graphicFileContent?.b64,
      );
      const blob: Blob = createBlobFromByteArray(
        byteArray,
        'text/plain;charset=utf-8',
      );
      zip.file(fileNames[index], blob);
    });
    const content: Blob = await zip.generateAsync({ type: 'blob' });
    saveAs(content, `${zipFileName}.zip`);
    return true;
  } catch (e) {
    throw new Error();
  }
};
