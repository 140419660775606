/* eslint-disable no-return-assign */
import * as React from 'react';
import { useTheme } from '@danfoss/etui-system';
import {
  Route,
  Link,
  withRouter,
  useHistory,
  matchPath,
  match,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  FeatureFallback,
  HeaderNavItem,
  HeaderNavItemProps,
  Icon,
  Tooltip,
  icons,
  useResponsive,
} from '@danfoss/etui-core';
import {
  getIsMatchedSoftwareVersion,
  useApp,
  useAuth,
  useUnit,
  useSegmentationValues,
  useXmlResource,
  LoadStates,
  HomeKey,
} from '@danfoss/etui-sm';
import { ErrorBoundary } from '@danfoss/etui-sm/components';
import {
  SOFTWARE_VERSION_PREFIX,
  REDIRECT_PARAMETER,
} from '@danfoss/etui-sm/constants';
import { Unit, User } from '@danfoss/etui-sm-xml';
import { routePaths } from 'routePaths';
import { GlobalStyle } from 'styles';
import { getDeviceIdAndDeviceTypeByEquipmentId, getShowBanner } from 'utils';
import { useQuery } from 'hooks';
import { QUERY_DEVICE_TYPE } from 'constants/query-constants';
import { HomePage } from 'pages/HomePage';
import { EmbeddedLoginPage, LoginPage } from 'pages/LoginPage';
import { AlarmsPage } from 'pages/AlarmsPage';
import { EquipmentPage } from 'pages/EquipmentPage';
import { EquipmentItemPage } from 'pages/EquipmentItemPage';
import { SchedulesPage } from 'pages/SchedulesPage';
import { InfoPage } from 'pages/InfoPage';
import { ConfigurationPage } from 'pages/ConfigurationPage';
// start-utilities
import { ClearedAlarmsPage } from 'pages/ClearedAlarmsPage';
import { DiagnosticsPage } from 'pages/DiagnosticsPage';
import { SecurityCompliancePage } from 'pages/SecurityCompliancePage';
import { SoftwareRollbackPage } from 'pages/SoftwareRollbackPage';
import { SystemPage } from 'pages/SystemPage';
import { FilesPage } from 'pages/FilesPage';
// end-utilities
import { RouteGuard, SwitchWithGuard } from 'components/CustomRouterComponents';
import { WithFlag } from 'feature-flags';
import { GraphicEditorPage } from 'pages/GraphicEditorPage';
import { GraphicViewerPage } from 'pages/GraphicViewerPage';
// import { usePersistLoginInfo } from 'hooks/use-persist-login-info';
import { ReportsPage } from 'pages/ReportsPage';
import { InformationPage } from 'pages/InformationPage';
import { HaccpReportPage } from 'pages/HaccpReportPage';
import { A, Div } from '@danfoss/etui-system-elements';
import { SiteInfoPage } from 'pages/InfoPage/pages/SiteInfoPage';
import { DeviceIntegrationModulePage } from 'pages/DeviceIntegrationModulePage';
import { getAppNameByUnits } from 'utils/get-app-name-by-units';
import { addSiteName } from 'utils/addressbook-helpers';
import { useScadaStorage } from 'pages/GraphicEditorPage/hooks/use-scada-storage';
import {
  useExperimentalFeatureFlag,
  useFeatureFlag,
  useFeatureHub,
} from 'hooks/featurehub/FeatureHubContext';
import { FeatureFlag } from 'hooks/featurehub/types';
import HistoryContextProvider from 'pages/HistoryPage/HistoryContext';
import OldHistoryPage from 'pages/HistoryPage/OldHistoryPage';
import { HistoryPage } from 'pages/HistoryPage';
import { useSuspendPollingStatus } from 'pages/ConfigurationPage/UnitConfiguration/hooks';
import { useSCADA } from 'hooks/scada/use-scada';
import {
  isExperimental,
  isEmbeddedApp,
  proxyUserApiUrl,
  WEBSITE_NAME,
} from '../../config';
import { AppLayout } from '../AppLayout';
import { AppRoute } from '../AppRoute';
import { getIsAllowedScadaStorage } from '../../utils/get-is-allowed-scada-storage';
import { WebVersionRedirect } from './WebVersionRedirect';
import {
  defaultHomeRedirect,
  usePreferredScreen,
} from './utils/getPreferredRoute';

type FlagProp = { on: FeatureFlag };

function App() {
  const { user } = useAuth();
  const { url } = useXmlResource();
  const { units, loadState, loadError } = useUnit();
  const [state, dispatch] = useApp();
  const { storage, isLoading, storageConnectionError } = useScadaStorage();
  const { scadaStorage, scadaDispatch } = useSCADA();
  const theme = useTheme();

  const { width, orientation } = useResponsive({
    xs: parseInt(theme.breakpoints[0], 10),
    sm: parseInt(theme.breakpoints[1], 10),
    md: parseInt(theme.breakpoints[2], 10),
    lg: parseInt(theme.breakpoints[3], 10),
  });
  const { replace, location, push } = useHistory();
  const query = useQuery();
  const { t, i18n } = useTranslation();
  const [reportBackBtn, setReportBackBtn] = React.useState(false);
  const [routerMatch, setRouterMatch] = React.useState<match<any> | null>();
  const [localGraphicRouterMatch, setLocalGraphicRouterMatch] =
    React.useState<match<any> | null>();
  const [activeAlarmsCount, setActiveAlarmsCount] = React.useState(0);
  const { client, setSegmentationData } = useFeatureHub();
  const equipmentFlagEnabled = useFeatureFlag('Equipment');
  const scadaViewWrightEnabled = useFeatureFlag('SCADAViewWright');
  const scadaViewEnabled = useFeatureFlag('SCADAView');
  const useExperimentalHistory = useExperimentalFeatureFlag(
    'ExperimentalHistoryChart',
  );
  const showBanner = getShowBanner();
  const skipSessionUpdate = true;
  const pollingEnabled = useSuspendPollingStatus(
    url,
    skipSessionUpdate,
    showBanner,
  );
  const name = getAppNameByUnits(units);
  const { iVal, mutate } = usePreferredScreen(url, user);

  // TODO: decide if needed (will add user login to address fiels, but currently breaks deeplinking)
  // usePersistLoginInfo();

  const AlarmTitle = ({ testId }: { testId: string }) => {
    return <Div testId={testId}>{t('t152')}</Div>;
  };

  const { segmentationValues } = useSegmentationValues({
    units,
    user,
    width,
    orientation,
    proxyConfig: state.proxy,
    proxyUserApiUrl,
    pollingInterval: 5000,
  });

  React.useEffect(() => {
    mutate();
  }, [url, user, mutate, location]);

  React.useEffect(() => {
    document.title = WEBSITE_NAME;
  }, []);

  React.useEffect(() => {
    scadaDispatch({
      type: 'SET_SCADA_STORAGE',
      payload: {
        instance: storage,
        isLoading,
        storageConnectionError,
      },
    });
  }, [storage, isLoading, storageConnectionError]);

  React.useEffect(() => {
    if (segmentationValues && setSegmentationData) {
      setSegmentationData(segmentationValues);
    }
  }, [segmentationValues, setSegmentationData]);

  const getPreferredRedirect = (
    user: User,
    loadState: LoadStates,
    loadError: string,
    preferredScreen: HomeKey,
  ) => {
    const isLoginPage = location.pathname.includes('login');
    if (!isLoginPage || !user || loadState !== LoadStates.DONE || loadError) {
      return;
    }
    replace(defaultHomeRedirect(preferredScreen));
  };

  React.useEffect(() => {
    (async () => {
      getPreferredRedirect(user, loadState, loadError, iVal as HomeKey);
      dispatch({
        type: 'SET_PREFERRED_URL',
        payload: iVal,
      });
      setReportBackBtn(location.pathname.includes('reports'));

      let graphicPathMatch = matchPath(location.pathname, {
        path: routePaths.pages.graphicEditor.showEditPath,
      });
      if (!graphicPathMatch) {
        graphicPathMatch = matchPath(location.pathname, {
          path: routePaths.pages.graphicEditor.showDrawPath,
        });
      }

      const localGraphicPathMatch = matchPath(location.pathname, {
        path: routePaths.pages.graphicLocalEditor.showLocalGraphicEditPath,
      });

      setRouterMatch(graphicPathMatch);
      setLocalGraphicRouterMatch(localGraphicPathMatch);
    })();
  }, [user, i18n, location, replace, loadState, loadError]);

  React.useEffect(() => {
    if (![state.xmlBackendIp, user?.password, user?.user, name].every(Boolean))
      return;

    addSiteName(
      {
        ipAddress: state.xmlBackendIp,
        user: user.user,
      },
      name,
    );
  }, [state.xmlBackendIp, user, name]);

  const getHomePageMenuItems = React.useCallback(
    (): WithFlag<HeaderNavItemProps>[] => [
      {
        title: t('t346'),
        as: StyledRouterWrappedLink,
        to: routePaths.pages.home,
        active: location.pathname === routePaths.pages.home,
        props: {
          a: { id: 'dashboardNav' },
        },
      },
      {
        title: t('t2237'),
        meta: {
          icon: scadaStorage.instance ? icons.WARNING_CIRCLE : null,
        },
        isDisabled: !scadaStorage.instance,
        metaRender: item =>
          scadaStorage.instance && (
            <Tooltip message={t('t3479')} placement="right">
              <Icon glyph={item.meta.icon} size={24} />
            </Tooltip>
          ),
        as: StyledRouterWrappedLink,
        to: !scadaStorage.instance ? '' : routePaths.pages.graphicViewer.home,
        active: location.pathname.includes(routePaths.pages.graphicViewer.home),
        flag: 'SCADAView',
        props: {
          a: { id: 'siteviewNav' },
        },
      },
    ],
    [location.pathname, t, scadaStorage],
  );

  function featureFlagGuard({ on }: { on: FeatureFlag }) {
    return client.feature(on).value;
  }

  function versionDependentGuard({
    units: allUnits,
    dependentVersionPrefix,
  }: {
    units: Unit[];
    dependentVersionPrefix: SOFTWARE_VERSION_PREFIX[];
  }) {
    return getIsMatchedSoftwareVersion(allUnits, dependentVersionPrefix);
  }

  function graphicStorageGuard({ mainSoftwareVersion = '' }) {
    const allowed = localGraphicRouterMatch
      ? false
      : mainSoftwareVersion
      ? getIsAllowedScadaStorage(units)
      : true;
    return allowed;
  }

  function StyledRouterWrappedLink(props) {
    return <Link component={A} {...props} />;
  }

  const handleEquipmentAlarmPage = (data: any) => {
    setActiveAlarmsCount(data?.length);
  };

  if (isEmbeddedApp && window.location.search.includes(REDIRECT_PARAMETER)) {
    return <WebVersionRedirect />;
  }

  const getPageComponent = (Page: any) => {
    return pollingEnabled ? (
      <>
        <Alert type="warning" message={t('t3798')} />
        <Page />
      </>
    ) : (
      <Page />
    );
  };

  return (
    <ErrorBoundary>
      <GlobalStyle />
      <SwitchWithGuard>
        <Route
          path={routePaths.pages.login}
          render={() => (isEmbeddedApp ? <EmbeddedLoginPage /> : <LoginPage />)}
        />
        <Route
          path={routePaths.pages.securityCompliance}
          render={() => <SecurityCompliancePage />}
        />
        <AppRoute
          path={routePaths.pages.home}
          exact={true}
          layout={AppLayout}
          layoutProps={{
            onGetActiveMenuItem: getHomePageMenuItems,
          }}
          component={() => getPageComponent(HomePage)}
        />
        <RouteGuard<FlagProp> guard={featureFlagGuard} on="AlarmManagement">
          <AppRoute
            path={routePaths.pages.alarms.listPath}
            layout={AppLayout}
            layoutProps={{
              onGetActiveMenuItem: pathname => {
                return [
                  {
                    title: t('t0'),
                    as: StyledRouterWrappedLink,
                    to: routePaths.pages.alarms.listActivePath,
                    active: pathname.includes(
                      routePaths.pages.alarms.listActivePath,
                    ),
                  },
                  {
                    title: t('t191'),
                    as: StyledRouterWrappedLink,
                    to: routePaths.pages.alarms.listAckedPath,
                    active: pathname.includes(
                      routePaths.pages.alarms.listAckedPath,
                    ),
                  },
                  {
                    title: t('t1'),
                    as: StyledRouterWrappedLink,
                    to: routePaths.pages.alarms.listClearedPath,
                    active: pathname.includes(
                      routePaths.pages.alarms.listClearedPath,
                    ),
                  },
                  {
                    title: t('t231'),
                    flag: 'AlarmManagementWrite',
                    as: StyledRouterWrappedLink,
                    to: routePaths.pages.alarms.listStatusPath,
                    active: pathname.includes(
                      routePaths.pages.alarms.listStatusPath,
                    ),
                    props: {
                      a: { id: 'statusAlarmsNav' },
                    },
                  },
                ];
              },
            }}
            render={() => (
              <React.Suspense fallback={null}>
                {getPageComponent(AlarmsPage)}
              </React.Suspense>
            )}
          />
        </RouteGuard>
        <AppRoute
          path={routePaths.pages.equipment.showPath}
          layout={AppLayout}
          layoutProps={{
            onBackButtonClick: (_, params) => {
              const { deviceType } = getDeviceIdAndDeviceTypeByEquipmentId(
                params.equipmentId,
              );
              query.set(QUERY_DEVICE_TYPE, deviceType);
              push({
                pathname: equipmentFlagEnabled
                  ? routePaths.pages.equipment.listPath
                  : routePaths.pages.home,
                search: equipmentFlagEnabled ? query.toString() : null,
              });
            },
            onGetActiveMenuItem: (pathname, params) => {
              return [
                {
                  title: t('t42'),
                  as: StyledRouterWrappedLink,
                  to: routePaths.pages.equipment.showStatus(
                    params?.equipmentId,
                  ),
                  active: pathname.includes(
                    routePaths.pages.equipment.showStatus(params?.equipmentId),
                  ),
                },
                {
                  key: 'alarms-count',
                  flag: 'AlarmManagement',
                  as: () => (
                    <>
                      <HeaderNavItem
                        props={{
                          root: {
                            testId: 'equipment-alarmCount-headerNavItem',
                          },
                        }}
                        bottom={true}
                        as={StyledRouterWrappedLink}
                        to={routePaths.pages.equipment.showAlarms(
                          params.equipmentId,
                        )}
                        active={pathname.includes(
                          routePaths.pages.equipment.showAlarms(
                            params.equipmentId,
                          ),
                        )}
                        iconOnly={false}
                        showBadge={activeAlarmsCount > 0}
                        badgeText={activeAlarmsCount.toString()}
                      >
                        <AlarmTitle testId="equipment-alarm-title" />
                      </HeaderNavItem>
                    </>
                  ),
                  props: {
                    a: { id: 'activeAlarmsButton' },
                  },
                },
                {
                  title: t('t902'),
                  as: StyledRouterWrappedLink,
                  flag: 'DeviceConfiguration',
                  to: routePaths.pages.equipment.showSettings(
                    params.equipmentId,
                  ),
                  active: pathname.includes(
                    routePaths.pages.equipment.showSettings(params.equipmentId),
                  ),
                },
              ];
            },
          }}
          render={() => (
            <React.Suspense fallback={null}>
              {pollingEnabled ? (
                <>
                  <Alert type="warning" message={t('t3798')} />
                  <EquipmentItemPage getAlarmData={handleEquipmentAlarmPage} />
                </>
              ) : (
                <EquipmentItemPage getAlarmData={handleEquipmentAlarmPage} />
              )}
            </React.Suspense>
          )}
        />
        <RouteGuard<FlagProp> guard={featureFlagGuard} on="Equipment">
          <AppRoute
            path={routePaths.pages.equipment.listPath}
            layout={AppLayout}
            layoutProps={{
              onGetActiveMenuItem: pathname => [
                {
                  title: t('t895'),
                  as: StyledRouterWrappedLink,
                  to: routePaths.pages.equipment.listPath,
                  active: pathname.includes(
                    routePaths.pages.equipment.listPath,
                  ),
                  props: {
                    a: { id: 'equipmentNav' },
                  },
                },
              ],
            }}
            render={() => (
              <React.Suspense fallback={null}>
                {getPageComponent(EquipmentPage)}
              </React.Suspense>
            )}
          />
        </RouteGuard>
        <RouteGuard<FlagProp> guard={featureFlagGuard} on="UnitInfos">
          <AppRoute
            path={routePaths.pages.info.site}
            layout={AppLayout}
            layoutProps={{
              onGetActiveMenuItem: () => [
                {
                  title: t('t3125'),
                  active: true,
                },
              ],
            }}
            render={() => (
              <React.Suspense fallback={null}>
                {getPageComponent(SiteInfoPage)}
              </React.Suspense>
            )}
          />
          <AppRoute
            path={routePaths.pages.info.unit}
            layout={AppLayout}
            layoutProps={{
              onGetActiveMenuItem: () => [
                {
                  title: t('t3113'),
                  active: true,
                },
              ],
            }}
            render={() => (
              <React.Suspense fallback={null}>
                <InfoPage />
              </React.Suspense>
            )}
          />
          <AppRoute
            path={routePaths.pages.information.home}
            layout={AppLayout}
            layoutProps={{
              onGetActiveMenuItem: () => [
                {
                  title: t('t3082'),
                  active: true,
                },
              ],
            }}
            render={() => (
              <React.Suspense fallback={null}>
                {getPageComponent(InformationPage)}
              </React.Suspense>
            )}
          />
        </RouteGuard>
        <RouteGuard<FlagProp> guard={featureFlagGuard} on="SystemConfiguration">
          <AppRoute
            path={routePaths.pages.configuration}
            layout={AppLayout}
            layoutProps={{
              onGetActiveMenuItem: () => [
                {
                  title: t('t156'),
                  as: StyledRouterWrappedLink,
                  to: routePaths.pages.configuration,
                  active: location.pathname.includes(
                    routePaths.pages.configuration,
                  ),
                  props: {
                    a: { id: 'configurationNav' },
                  },
                },
              ],
            }}
            render={() => (
              <React.Suspense fallback={null}>
                <ConfigurationPage />
              </React.Suspense>
            )}
          />
        </RouteGuard>
        <RouteGuard<FlagProp> guard={featureFlagGuard} on="ScheduleView">
          <AppRoute
            path={routePaths.pages.schedules.listPath}
            layout={AppLayout}
            layoutProps={{
              onGetActiveMenuItem: pathname => [
                {
                  title: t('t37'),
                  as: StyledRouterWrappedLink,
                  to: routePaths.pages.schedules.listPath,
                  active: pathname.includes(
                    routePaths.pages.schedules.listPath,
                  ),
                  props: {
                    a: { id: 'schedulesNav' },
                  },
                },
              ],
            }}
            render={() => (
              <React.Suspense fallback={null}>
                {getPageComponent(SchedulesPage)}
              </React.Suspense>
            )}
          />
        </RouteGuard>
        <RouteGuard<FlagProp> guard={featureFlagGuard} on="History">
          <AppRoute
            path={routePaths.pages.history}
            layout={AppLayout}
            layoutProps={{
              onGetActiveMenuItem: () => [
                {
                  title: t('t153'),
                  as: StyledRouterWrappedLink,
                  to: routePaths.pages.history,
                  active: location.pathname.includes(routePaths.pages.history),
                },
              ],
            }}
            render={() => (
              <React.Suspense fallback={null}>
                {useExperimentalHistory ? (
                  <HistoryContextProvider>
                    {getPageComponent(HistoryPage)}
                  </HistoryContextProvider>
                ) : (
                  getPageComponent(OldHistoryPage)
                )}
              </React.Suspense>
            )}
          />
        </RouteGuard>
        <RouteGuard
          guard={versionDependentGuard}
          units={units}
          dependentVersionPrefix={[
            SOFTWARE_VERSION_PREFIX.G08,
            SOFTWARE_VERSION_PREFIX.G09,
            SOFTWARE_VERSION_PREFIX.G10,
          ]}
        >
          <RouteGuard<FlagProp> guard={featureFlagGuard} on="Reports">
            <AppRoute
              path={routePaths.pages.reports}
              layout={AppLayout}
              layoutProps={{
                onGetActiveMenuItem: () => [
                  {
                    title: t('t3036'),
                    active: true,
                  },
                ],
              }}
              render={() => (
                <React.Suspense fallback={null}>
                  {getPageComponent(ReportsPage)}
                </React.Suspense>
              )}
            />
          </RouteGuard>
        </RouteGuard>
        <RouteGuard
          guard={versionDependentGuard}
          units={units}
          dependentVersionPrefix={[
            SOFTWARE_VERSION_PREFIX.G08,
            SOFTWARE_VERSION_PREFIX.G09,
            SOFTWARE_VERSION_PREFIX.G10,
          ]}
        >
          <RouteGuard<FlagProp> guard={featureFlagGuard} on="AlarmManagement">
            <AppRoute
              path={routePaths.pages.clearedAlarms}
              layout={AppLayout}
              layoutProps={{
                onGetActiveMenuItem: () => [
                  {
                    title: t('t1'),
                    active: true,
                  },
                ],
              }}
              render={() => (
                <React.Suspense fallback={null}>
                  {getPageComponent(ClearedAlarmsPage)}
                </React.Suspense>
              )}
            />
          </RouteGuard>
          <RouteGuard<FlagProp> guard={featureFlagGuard} on="DeviceIntegration">
            <AppRoute
              path={routePaths.pages.deviceIntegration}
              layout={AppLayout}
              layoutProps={{
                onGetActiveMenuItem: () => [
                  {
                    title: t('t3299'),
                    active: true,
                  },
                ],
              }}
              render={() => (
                <React.Suspense fallback={null}>
                  {getPageComponent(DeviceIntegrationModulePage)}
                </React.Suspense>
              )}
            />
          </RouteGuard>
          {reportBackBtn ? (
            <RouteGuard<FlagProp> guard={featureFlagGuard} on="UnitInfos">
              <AppRoute
                path={routePaths.pages.diagnostics}
                layout={AppLayout}
                layoutProps={{
                  onBackButtonClick: () => {
                    push({
                      pathname: routePaths.pages.system.softwareManagement,
                    });
                  },
                  onGetActiveMenuItem: () => [
                    {
                      title: t('t899'),
                      active: true,
                    },
                  ],
                }}
                render={() => (
                  <React.Suspense fallback={null}>
                    {getPageComponent(DiagnosticsPage)}
                  </React.Suspense>
                )}
              />
            </RouteGuard>
          ) : (
            <RouteGuard<FlagProp> guard={featureFlagGuard} on="UnitInfos">
              <AppRoute
                path={routePaths.pages.diagnostics}
                layout={AppLayout}
                layoutProps={{
                  onGetActiveMenuItem: () => [
                    {
                      title: t('t899'),
                      active: true,
                    },
                  ],
                }}
                render={() => (
                  <React.Suspense fallback={null}>
                    {getPageComponent(DiagnosticsPage)}
                  </React.Suspense>
                )}
              />
            </RouteGuard>
          )}
          {reportBackBtn ? (
            <RouteGuard<FlagProp> guard={featureFlagGuard} on="Reports">
              <AppRoute
                path={routePaths.pages.haccp}
                layout={AppLayout}
                layoutProps={{
                  onBackButtonClick: () => {
                    push({
                      pathname: routePaths.pages.reports,
                    });
                  },
                  onGetActiveMenuItem: () => [
                    {
                      title: t('t3110'),
                      active: true,
                    },
                  ],
                }}
                render={() => (
                  <React.Suspense fallback={null}>
                    {getPageComponent(HaccpReportPage)}
                  </React.Suspense>
                )}
              />
            </RouteGuard>
          ) : (
            <RouteGuard<FlagProp> guard={featureFlagGuard} on="Reports">
              <AppRoute
                path={routePaths.pages.haccp}
                layout={AppLayout}
                layoutProps={{
                  onGetActiveMenuItem: () => [
                    {
                      title: t('t3110'),
                      active: true,
                    },
                  ],
                }}
                render={() => (
                  <React.Suspense fallback={null}>
                    {getPageComponent(HaccpReportPage)}
                  </React.Suspense>
                )}
              />
            </RouteGuard>
          )}
          <AppRoute
            path={routePaths.pages.system.home}
            layout={AppLayout}
            layoutProps={{
              onGetActiveMenuItem: () => [
                {
                  title: t('t2261'),
                  active: true,
                },
              ],
            }}
            render={() => (
              <React.Suspense fallback={null}>
                <SystemPage />
              </React.Suspense>
            )}
          />
          <AppRoute
            path={routePaths.pages.softwareRollback}
            layout={AppLayout}
            layoutProps={{
              onGetActiveMenuItem: () => [
                {
                  title: t('t898'),
                  active: true,
                },
              ],
            }}
            render={() => (
              <React.Suspense fallback={null}>
                {getPageComponent(SoftwareRollbackPage)}
              </React.Suspense>
            )}
          />
          {isExperimental && (
            <AppRoute
              path={routePaths.pages.files.listPath}
              layout={AppLayout}
              layoutProps={{
                onGetActiveMenuItem: () => [
                  {
                    title: t('t653'),
                    active: true,
                  },
                ],
              }}
              render={() => (
                <React.Suspense fallback={null}>
                  {getPageComponent(FilesPage)}
                </React.Suspense>
              )}
            />
          )}
        </RouteGuard>
        <RouteGuard
          guard={graphicStorageGuard}
          mainSoftwareVersion={units[0]?.software}
        >
          {!routerMatch ? (
            <>
              {scadaViewWrightEnabled && (
                <AppRoute
                  path={routePaths.pages.graphicEditor.listPath}
                  layout={AppLayout}
                  layoutProps={{
                    onGetActiveMenuItem: () => {
                      return [
                        {
                          title: t('t2203'),
                          active: true,
                        },
                      ];
                    },
                  }}
                  render={() => (
                    <React.Suspense fallback={null}>
                      {getPageComponent(GraphicEditorPage)}
                    </React.Suspense>
                  )}
                />
              )}
              {scadaViewEnabled && (
                <AppRoute
                  path={routePaths.pages.graphicViewer.home}
                  layout={AppLayout}
                  layoutProps={{
                    onGetActiveMenuItem: getHomePageMenuItems,
                  }}
                  render={() => (
                    <React.Suspense fallback={null}>
                      {getPageComponent(GraphicViewerPage)}
                    </React.Suspense>
                  )}
                />
              )}
            </>
          ) : (
            <>
              {scadaViewWrightEnabled && (
                <AppRoute
                  path={routePaths.pages.graphicEditor.listPath}
                  layout={AppLayout}
                  layoutProps={{
                    onBackButtonClick: () => {
                      push({
                        pathname: routePaths.pages.graphicEditor.listPath,
                      });
                    },
                    onGetActiveMenuItem: pathname => {
                      const {
                        params: { graphId },
                      } = routerMatch as any;
                      return [
                        {
                          title: t('t2204'),
                          as: StyledRouterWrappedLink,
                          to: routePaths.pages.graphicEditor.showEdit(graphId),
                          active: pathname.includes(
                            routePaths.pages.graphicEditor.showEdit(graphId),
                          ),
                          flag: 'SCADAEdit',
                        },
                      ];
                    },
                  }}
                  render={() => (
                    <React.Suspense fallback={null}>
                      {getPageComponent(GraphicEditorPage)}
                    </React.Suspense>
                  )}
                />
              )}

              {scadaViewEnabled && (
                <AppRoute
                  path={routePaths.pages.graphicViewer.home}
                  layout={AppLayout}
                  layoutProps={{
                    onGetActiveMenuItem: () => [
                      {
                        title: t('t2237'),
                        active: true,
                      },
                    ],
                  }}
                  render={() => (
                    <React.Suspense fallback={null}>
                      {getPageComponent(GraphicViewerPage)}
                    </React.Suspense>
                  )}
                />
              )}
            </>
          )}
        </RouteGuard>
        <RouteGuard<FlagProp> guard={featureFlagGuard} on="LocalGraphicView">
          {localGraphicRouterMatch && (
            <AppRoute
              path={routePaths.pages.graphicLocalEditor.listPath}
              layout={AppLayout}
              layoutProps={{
                onBackButtonClick: () => {
                  push({
                    pathname: routePaths.pages.graphicLocalEditor.listPath,
                  });
                },
                onGetActiveMenuItem: pathname => {
                  const {
                    params: { graphId },
                  } = localGraphicRouterMatch as any;
                  return [
                    {
                      title: t('t2204'),
                      as: StyledRouterWrappedLink,
                      to: routePaths.pages.graphicLocalEditor.showLocalGraphicEdit(
                        graphId,
                      ),
                      active: pathname.includes(
                        routePaths.pages.graphicLocalEditor.showLocalGraphicEdit(
                          graphId,
                        ),
                      ),
                      flag: 'SCADAEdit',
                    },
                  ];
                },
              }}
              render={() => (
                <React.Suspense fallback={null}>
                  {getPageComponent(GraphicEditorPage)}
                </React.Suspense>
              )}
            />
          )}
        </RouteGuard>
        <AppRoute
          path={routePaths.pages.notFound}
          layout={AppLayout}
          layoutProps={{}}
          render={() => <FeatureFallback />}
        />
      </SwitchWithGuard>
    </ErrorBoundary>
  );
}

export default withRouter(App);
