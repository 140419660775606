/**
 * This file is imported from https://dev.azure.com/danfoss/AK-SM/_git/aksm.800a.release_files?path=%2Fhtml%2Fassets%2Fjs%2FimageFileParser_GFE.js&_a=contents&version=GBdevelop
 * The 'createImgBytesArray' is the only which was modified to simply parse from base64 string instead of reading from DB
 */

let projectDatapointsFlp = [];

function addNewDataPointFlp(item, index) {
  projectDatapointsFlp.splice(index, 0, {
    name: item.name,
    host: item.host,
    iPointName: item.iPointName,
    iUnits: item.iUnits,
    iParameterName: item.iParameterName,
    iSize: item.iSize,
    nodetype: item.nodetype,
    node: item.node,
    mod: item.mod,
    point: item.point,
    parmIndex: item.parmIndex,
    x: item.x,
    y: item.y,
    generic_node: item.generic_node,
  });
}

function clearProjectDatapointsFlp() {
  projectDatapointsFlp = [];
}

function createImgBytesArray(b64FileVersion, callback) {
  const raw = atob(b64FileVersion);
  const array = new Uint8Array(new ArrayBuffer(raw.length));
  for (let i = 0; i < raw.length; i++) {
    array[i] = raw.charCodeAt(i);
  }

  callback(array);
}

export default function parseFlpFile(b64FileVersion, imageOnly, callback) {
  const imageStartByte = 21;
  let datapointsOffset;
  const ReservedSpace = 32;
  const pointBytesNumber = 16 + ReservedSpace;

  // Open image as byte array
  createImgBytesArray(b64FileVersion, array => {
    if (array === null || array === undefined) {
      callback(null);
    }

    // 1. Calculate image size
    const JpgSize =
      (array[0] << 24) + (array[1] << 16) + (array[2] << 8) + array[3];

    // 2. Read image bytes
    const JpgImageBytes = new Uint8Array(JpgSize);
    for (let count = 0; count < JpgSize; count++) {
      JpgImageBytes[count] = array[count + imageStartByte];
    }

    // If we need only image bytes - return now
    if (imageOnly === true) callback(JpgImageBytes);

    // 3. Check the file format (necessary to have "DEADDEAD" at the end)
    const flpVersionControl = new Array(4);
    for (let i = 0; i < 4; i++) {
      flpVersionControl[i] = array[i + imageStartByte + JpgSize];
    }
    if (
      flpVersionControl[0] !== 0xde ||
      flpVersionControl[1] !== 0xad ||
      flpVersionControl[2] !== 0xde ||
      flpVersionControl[3] !== 0xad
    ) {
      // Error: where's the dead!
      callback(null);
    }

    // 4. Read system datapoints
    datapointsOffset = imageStartByte + JpgSize + 4 + 2;
    let total;
    if (array.length > datapointsOffset) {
      total =
        (array[imageStartByte + JpgSize + 4] << 8) +
        array[imageStartByte + JpgSize + 4 + 1];
    } else {
      total = 0;
    }

    clearProjectDatapointsFlp();

    if (total >= 0) {
      for (let i = 0; i < total; i++) {
        const pointOffset = datapointsOffset + i * pointBytesNumber;
        const param = {
          name: '',
          host: array[pointOffset + 0],
          iPointName: array[pointOffset + 1],
          iUnits: array[pointOffset + 2],
          iParameterName: array[pointOffset + 3],
          iSize: array[pointOffset + 4],
          nodetype: array[pointOffset + 5],
          node: array[pointOffset + 6],
          mod: array[pointOffset + 7],
          point: array[pointOffset + 8],
          parmIndex: (array[pointOffset + 9] << 8) + array[pointOffset + 10],
          x: (array[pointOffset + 11] << 8) + array[pointOffset + 12],
          y: (array[pointOffset + 13] << 8) + array[pointOffset + 14],
          generic_node: array[pointOffset + 15],
        };
        addNewDataPointFlp(param, projectDatapointsFlp.length);
      }
    } else {
      // Error: wrong datapoints number
      callback(null);
    }

    callback(JpgImageBytes, projectDatapointsFlp);
  });
}
