import { Unit, UnitNetwork } from '@danfoss/etui-sm-xml';
import { siteInfo } from '../constants';

export const getUnitDetails = (
  units: UnitNetwork[],
  network: UnitNetwork,
): siteInfo => {
  const internalUnit: UnitNetwork = units?.filter(unit => {
    return unit?.addr === network?.addr;
  })[0];
  return getUnitDetailsObj(internalUnit);
};

export const getUnitDetailsObj = (internalUnit: UnitNetwork): siteInfo => {
  return {
    ip: internalUnit?.ip,
    port: internalUnit?.port,
    name: internalUnit?.name,
  };
};

export const checkIfExternalIp = (
  unit: Partial<Unit>,
  unitInternetValue: string,
  ip: string,
): string => {
  return +unit?.external_internet === 0 || ip === '0' ? '-' : unitInternetValue;
};

export const getMatchingUnitObject = (
  unitNetworkList: UnitNetwork[],
  unitName: string,
) => {
  return unitNetworkList.find(({ name }) => name === unitName);
};

export function getArray<T>(data: T | T[]): T[] {
  if (!data) {
    return [];
  }
  return Array.isArray(data) ? (data as T[]) : [data];
}

export const getConnectedUnitInfo = (units: Partial<Unit> = {}) => {
  const {
    unit_name = '',
    unit = [],
    external_internet = '0',
    unit_internet = [],
  } = units;
  const unitList = getArray(
    String(external_internet) === '1' ? unit_internet : unit,
  );
  return getMatchingUnitObject(unitList, unit_name);
};

export const getDnsInfo = (
  connectedUnitName: string,
  validNetworks: UnitNetwork[],
) => {
  return validNetworks.find(({ name }) => name === connectedUnitName);
};
