export function createImgSourceFromBytes(bytesArray, imageType) {
  let binary = '';
  for (let i = 0; i < bytesArray.length; i++) {
    binary += String.fromCharCode(bytesArray[i]);
  }
  let base64Src = `data:image/${imageType};base64,`;
  const imageString = window.btoa(binary);
  base64Src += imageString;
  return { base64Src, imageString };
}
