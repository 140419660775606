import {
  fetchXMLData,
  FetchXMLResponse,
  Unit,
  UnitNetwork,
  User,
  XML_ACTION,
} from '@danfoss/etui-sm-xml';
import { danfossJsonToDrawIoXml } from '@danfoss/etui-scada/utils/danfoss-to-draw-io-xml';
import { getArray } from 'utils';
import { TFunction } from 'i18next';
import { fetchAllAkioParameters } from '../actions/fetch-all-akio-parameters';
import { getAllGenericParameters } from '../actions/get-all-generic-parameters';
import { MIGRATION_FAILURE } from '../components/DeviceParameterModal/utils/migration-error-codes';
import { getSourceIdMeta } from '../actions';
import { FlpFile } from '../context/GraphicMigrationContext';
import { createImgSourceFromBytes } from './create-image-source-from-bytes';
import parseFlpFile from './parse-flp-file';
import { parseVz2File, parseVizFile } from './parse-viz-file';

const USER_HTML = 'user_html';
const RAMDISK_EDF = 'ramdisk_edf';

export type Flp = {
  filename: string;
  drawIOXml: string;
  imageParsed: Uint8Array;
  mappings: { cellId: string; paramId: string }[];
};

export type Viz = {
  filename: string;
  drawIOXml?: string;
  imageParsed: Uint8Array;
  mappings?: { cellId: string; paramId: string }[];
  b64: b64Vz2;
};

export type Dpj = {
  name?: string;
  flpFiles: Flp[];
};

export type LocalDpj = {
  name?: string;
  vizFiles: Viz[];
};
export type b64Vz2 = {
  name: string;
  b64: string;
};

export type FileStaus = {
  name?: any;
  fileContent: Flp;
  done?: boolean;
  loading: boolean;
  olderVersion?: boolean;
};

export type ReadFlpDirectoryResponse = FetchXMLResponse<{
  file: FlpFile[];
}>;

export async function parseFlpFileAsync(
  b64File,
): Promise<{ imageParsed: string; projectDatapointsParsed: any[] }> {
  return new Promise((resolve, err) => {
    parseFlpFile(b64File, false, (imageParsed, projectDatapointsParsed) => {
      if (!projectDatapointsParsed) {
        err(Error('Could not parse flpFile'));
      }
      resolve({ imageParsed, projectDatapointsParsed });
    });
  });
}

export async function parseFlpAsync(
  name,
  imageParsed,
  getDataPoints,
): Promise<Flp> {
  return new Promise((resolve, err) => {
    getDataPoints()
      .then(points => {
        const { imageString: backgroundImage } = createImgSourceFromBytes(
          imageParsed,
          'jpeg',
        );

        danfossJsonToDrawIoXml({
          value: Array.isArray(points) ? points : [points],
          backgroundImage,
        })
          .then(({ xml, mappings }) => {
            xml = xml.replace('\\n', '').replace('\\', '');
            resolve({ filename: name, imageParsed, drawIOXml: xml, mappings });
          })
          .catch(err);
      })
      .catch(err);
  });
}

export async function fetchDpjFiles(url, user) {
  return fetchXMLData<{ dpj_files: any }>({
    url,
    attributes: {
      action: XML_ACTION.READ_DPJ_FILE,
      user: user.user,
      password: user.password,
      lang: user.language,
    },
  });
}

export async function readDirectory([url, user, path]: [
  string,
  User,
  string,
]): Promise<ReadFlpDirectoryResponse> {
  const directory = path;
  return fetchXMLData({
    url,
    attributes: {
      action: XML_ACTION.READ_DIRECTORY,
      user: user.user,
      password: user.password,
      lang: user.language,
      directory,
    },
  });
}

function compareParameterToPoint(param, point) {
  const meta = getSourceIdMeta(param.id);

  return (
    (param.node?.toString() || meta.node) === point.node.toString() &&
    (param.mod?.toString() || meta.mod) === point.mod.toString() &&
    (param.point?.toString() || meta.point) === point.point.toString() &&
    (param.host?.toString() || meta.host) === point.host.toString() &&
    (param.nodeType?.toString() || meta.nodetype) === point.nodetype.toString()
  );
}

function compareGenericParameterToPoint(param, point) {
  const { host: paramhost } = getSourceIdMeta(param.id);
  return (
    point.generic_node > 0 &&
    `${paramhost}` === `${point.host}` &&
    param.node === point.node.toString() &&
    param.paramIndex === point.parmIndex
  );
}

function updatePointsFromParameters(
  generic: boolean,
  parameters,
  points,
  comparer: (parameter, point) => boolean,
) {
  return getArray(points)
    .filter(point =>
      generic ? point.generic_node > 0 : point.generic_node === 0,
    )
    .map(point => {
      const parameter: any = parameters.find((param: any) =>
        comparer(param, point),
      );
      point.name = parameter?.name;
      return { paramId: parameter?.id, ...point };
    });
}

export async function getDataPointsFromUnits(
  units,
  user,
  getFirstValidUrl: (unit: Unit) => string,
  getFirstValidUnitNetwork: (unit: Unit) => UnitNetwork,
  parsedDatapoints = null,
) {
  // Update generic points with name and id
  const genericNodes = parsedDatapoints
    ? getArray(parsedDatapoints)
        ?.filter(point => point.generic_node > 0)
        .map(point => `${point.node}`)
    : [];
  const genericParameters = await getAllGenericParameters(
    units,
    user,
    getFirstValidUrl,
    getFirstValidUnitNetwork,
    genericNodes,
  );
  const genericPoints = updatePointsFromParameters(
    true,
    genericParameters,
    parsedDatapoints,
    compareGenericParameterToPoint,
  );

  // Update AK-IO parameters with name and id
  const akioParameters = await fetchAllAkioParameters(
    units,
    user,
    getFirstValidUrl,
    getFirstValidUnitNetwork,
  );
  const akioPoints = updatePointsFromParameters(
    false,
    akioParameters,
    parsedDatapoints,
    compareParameterToPoint,
  );
  return [...genericPoints, ...akioPoints];
}

export async function readFlpValue(url, user) {
  return fetchXMLData({
    url,
    attributes: {
      action: XML_ACTION.READ_FLP_VALUE,
      user: user.user,
      password: user.password,
      lang: user.language,
      index: '0',
      type: '0',
    },
  });
}

async function getFLPFileContent(url, user, filename, offset, retryCount) {
  let fileContent;

  try {
    fileContent = await fetchXMLData({
      url,
      attributes: {
        action: XML_ACTION.GET_FILE,
        user: user.user,
        password: user.password,
        lang: user.language,
        filename,
        offset,
        access_area: USER_HTML,
      },
    });
  } catch (e) {
    if (e && retryCount < 3) {
      retryCount++;
      fileContent = getFLPFileContent(url, user, filename, offset, retryCount);
      return fileContent;
    }
    throw new Error(MIGRATION_FAILURE);
  }
  return fileContent;
}

/* async function getFLPFContent(url, user, filename, offset, retryCount) {
  let file;
  try {
    file = await fetchXMLFileData({
      action: XML_ACTION.GET_FILE,
      offset: 0,
      attributes: { offset: 0, filename },
      url,
      user: user.user,
      password: user.password,
      lang: user.language,
      decodeFile: true,
    });
    return file;
  } catch (e) {
    if (e && retryCount < 3) {
      retryCount++;
      file = getFLPFileContent(url, user, filename, offset, retryCount);
      return file;
    }
    throw new Error(MIGRATION_FAILURE);
  }
} */
export async function fetchFlpFile(
  url,
  user,
  filename,
  offset,
  isSM800AGetFileVersion,
) {
  if (!isSM800AGetFileVersion) {
    filename = `html/${filename}`;
  }
  const retryCount = 0;

  const fileContent = getFLPFileContent(
    url,
    user,
    filename,
    offset,
    retryCount,
  );
  return fileContent;
}

export async function fetchDpjFile(user, url, filename) {
  return fetchXMLData<{ project: any }>({
    url,
    attributes: {
      action: XML_ACTION.GET_FILE,
      user: user.user,
      password: user.password,
      lang: user.language,
      filename,
      offset: 0,
      access_area: RAMDISK_EDF,
    },
  });
}

export async function parseVizFileAsync(
  b64File,
  isSm800A: boolean,
  t: TFunction,
  currentUnit?: Unit,
): Promise<{
  imageParsed: Uint8Array;
  projectDatapointsParsed: any[];
  actualB64ByteArray?: Uint8Array;
}> {
  return isSm800A
    ? new Promise((resolve, err) => {
        parseVz2File(b64File, false, (imageParsed, projectDatapointsParsed) => {
          if (!projectDatapointsParsed) {
            err(Error(t('t3635')));
          }
          resolve({ imageParsed, projectDatapointsParsed });
        });
      })
    : new Promise((resolve, err) => {
        parseVizFile(
          b64File,
          t,
          currentUnit,
          (
            imageParsed: Uint8Array,
            projectDatapointsParsed,
            actualB64ByteArray: Uint8Array,
          ) => {
            if (!projectDatapointsParsed) {
              err(Error(t('t3634')));
            }
            resolve({
              imageParsed,
              projectDatapointsParsed,
              actualB64ByteArray,
            });
          },
        );
      });
}

export async function parseVizAsync(
  name,
  vizFileB64,
  imageParsed,
  getDataPoints,
): Promise<Viz> {
  return new Promise((resolve, err) => {
    getDataPoints()
      .then(points => {
        const { imageString: backgroundImage } = createImgSourceFromBytes(
          imageParsed,
          'png',
        );
        danfossJsonToDrawIoXml({
          value: Array.isArray(points) ? points : [points],
          backgroundImage,
        })
          .then(({ xml, mappings }) => {
            xml = xml.replace('\\n', '').replace('\\', '');
            resolve({
              filename: name,
              b64: vizFileB64,
              imageParsed,
              drawIOXml: xml,
              mappings,
            });
          })
          .catch(err);
      })
      .catch(err);
  });
}
