import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Device,
  Unit,
  getUnitUrl,
  ConfigurationTabItem,
  ConfigurationListItem,
  XML_DEVICE_COMBO,
  PATHNAMES,
} from '@danfoss/etui-sm-xml';
import {
  ContentEmpty,
  ContentSpinner,
  useXmlResource,
  useAuth,
  getIsMatchedSoftwareVersion,
  SOFTWARE_VERSION_PREFIX,
  useUnit,
  EmptyState,
  useModal,
  useApp,
  HomeKey,
} from '@danfoss/etui-sm/';
import useSWR, { KeyedMutator } from 'swr';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import {
  ContainerDimensions,
  TableVirtualizedInfinite,
} from '@danfoss/etui-core';
import { Div } from '@danfoss/etui-system-elements';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { usePrevious } from 'hooks';
import {
  useConfiguration,
  useConfigurationAuth,
  useConfigurationModals,
  useConfigurationMenu,
  useConfigurationCustomActions,
  TABLE_ADDRESS,
  OLD_CONFIG_TYPE,
} from '../..';
import {
  ConfigurationTabByUnitResponse,
  downloadHistoryArchive,
  fetchConfigurationTabByUnit,
  fetchOrFallbackConfigTabList,
  readPollingSuspensionStatus,
  revalidateTabCombo,
  setPollingToActive,
  writeConfigurationListItem,
} from '../../actions';
import { withErrorHandled } from '../../utils/with-error-handled';
import {
  initialPage,
  isPaginationEnabledInUploadPage,
  updateDataListWithMultiOptions,
  multiOptionsListLength,
} from '../../utils/set-multi-options-list';
import {
  checkPasswordUpdatedForCurrentUser,
  createSequenceFromNumber,
  getAuditNames,
  getBpidx,
  getIsEditableByTab,
  getTableDataRefreshInterval,
  TABLE_IDS,
  updateListInSWRCache,
} from './utils';
import { NodeTypes, getAddresses } from './utils/address-utils';
import { prepareAuditTableData } from './utils/auditTrailTableConfig';
import { getFallbackData } from './utils/get-fallback-data';
import { ConfigurationItemNonVirtualizedList } from './ConfigurationItemNonVirtualizedList';
import { DownloadInProgressModal, PollingSuspensionModal } from './modals';
import { useSWRWithFetchingCount } from './hooks/useSWRWithFetchCount';
import { PollingStatusResponse } from './types';

export type ConfigurationListItemAddress = ConfigurationListItem & {
  addressLine: ConfigurationListItem;
  optionsLine: ConfigurationListItem;
};

export type ConfigurationItemContentListProps = {
  menuId?: string;
  unit: Unit;
  tab?: ConfigurationTabItem;
  isconfigure?: '0' | '1';
  itemRenderer?: (...args: any[]) => React.ReactNode;
  secondLevelMenuItems?: ConfigurationTabItem;
  onPasswordUpdatedForCurrentUser?: (
    passwordUpdated: boolean,
    updatedPassword: string,
  ) => void;
  device: Device;
  deviceGroup: string;
  deviceSubgroup: string;
  page: string;
  updateTime?: (menuId: string, currentTimeMs: number) => void;
  updatePageData?: KeyedMutator<ConfigurationTabByUnitResponse>;
};

function ConfigurationItemContentList({
  menuId,
  unit,
  tab = null,
  isconfigure = '0',
  itemRenderer,
  secondLevelMenuItems,
  onPasswordUpdatedForCurrentUser,
  device,
  deviceGroup,
  deviceSubgroup,
  page,
  updateTime,
  updatePageData,
}: ConfigurationItemContentListProps) {
  const { openModals } = useConfigurationModals();
  const { user } = useAuth();
  const { url: unitURL } = useXmlResource();
  const xmlBackendURL = getUnitUrl(unitURL, unit);
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    location: { pathname },
  } = useHistory();
  const { getItemIsAuthorized } = useConfigurationAuth(tab, user);
  const {
    cachedDevices,
    multiOptionsList,
    onSetConfigurationDataItem,
    onSetMultiOptionsList,
  } = useConfiguration();
  const [, dispatch] = useApp();

  const [tabId, tabOrder] = menuId.split('-');

  const { searchMode, searchValue, foodGroup } = useConfigurationMenu();

  const {
    isDownloadPage,
    isDownloadInprogressInNetworks,
    isDownloadComplete,
    pollingData,
    menuId: currentMenuId,
    progressText,
    setMenuId,
    isDownloadEnabledInNetworks,
    setIsDownloadEnabledInNetworks,
    setData,
    setDownloadComplete,
    setPollingData,
  } = useConfigurationCustomActions();
  currentMenuId !== menuId && setMenuId(menuId);

  const [downloadCompletionCallCount, setDownloadCompletionCallCount] =
    React.useState<number>(0);
  const [startPolling, setStartPolling] = React.useState<boolean>(false);
  const [pollingShown, setPollingShown] = React.useState<boolean>(false);
  const [finalConfigData, setFinalConfigData] = React.useState([]);

  const [processedList, setProcessedList] = React.useState([]);
  const isAuditTrail = menuId.includes(TABLE_ADDRESS.AUDIT_TRAIL);
  const { units } = useUnit();
  const isSm800A = getIsMatchedSoftwareVersion(units, [
    SOFTWARE_VERSION_PREFIX.G09,
    SOFTWARE_VERSION_PREFIX.G10,
  ]);
  const refreshFetchIntervalValue = getTableDataRefreshInterval(
    pathname,
    isSm800A,
  );

  const handleConfirm = () => {
    setStartPolling(true);
    hidePollingModal();
    setIsDownloadEnabledInNetworks(false);
    setPollingShown(true);
  };

  const handleClose = () => {
    hidePollingModal();
    setIsDownloadEnabledInNetworks(false);
    setPollingShown(true);
  };

  const [showPollingModal, hidePollingModal] = useModal(
    PollingSuspensionModal,
    { onConfirm: handleConfirm, onClose: handleClose },
  );

  const useTabCombo = Boolean(tab?.combo);
  const tabIsEditable = getIsEditableByTab(tab);
  const configuretype = tab?.configuretype || '0';
  const isMcxAddressTab = pathname.includes(TABLE_ADDRESS.MCX);

  const useparent = '0';
  const bpidx = getBpidx(device);
  const stype = device?.stype || null;
  const node = device?.node || null;
  const combo = isMcxAddressTab
    ? XML_DEVICE_COMBO.COMBO_MCX
    : useTabCombo
    ? tab.combo
    : device.combo || '0';
  const isCPT = combo === XML_DEVICE_COMBO.COMBO_CPT;
  const nodetype = isCPT ? NodeTypes.NODE_TYPE_NA : device?.nodetype || null;
  const arg1 = isCPT ? foodGroup?.index : device?.arg1 || null;
  const arg2 = device?.arg2 || null;
  const arg3 = device?.arg3 || null;
  const isOnline = device?.online ? +device.online : null;
  const old_cfgtype = isMcxAddressTab
    ? OLD_CONFIG_TYPE.MCX
    : secondLevelMenuItems?.configuretype || '0';
  const fallbackData = getFallbackData(tabId, tabOrder);
  const prepareArguments = (
    pageNumber: string,
    inputCombo?: '0' | XML_DEVICE_COMBO,
  ) => [
    xmlBackendURL,
    unit,
    menuId,
    user,
    cachedDevices,
    true, // skipSessionUpdate
    useparent,
    isconfigure,
    bpidx,
    stype,
    nodetype,
    node,
    deviceGroup || '0',
    deviceSubgroup || '0',
    pageNumber,
    inputCombo || combo,
    configuretype,
    arg1,
    arg2,
    arg3,
    isOnline,
    old_cfgtype,
  ];
  const isEquipment = pathname?.includes(PATHNAMES.EQUIPMENT);

  const { data, error, mutate, isValidating, fetchingCount } =
    useSWRWithFetchingCount<ConfigurationTabByUnitResponse>(
      () => (openModals.length || searchMode ? null : prepareArguments(page)),
      fetchConfigurationTabByUnit,
      {
        refreshInterval: refreshFetchIntervalValue,
      },
    );
  const preparedArgs = React.useMemo(
    () => prepareArguments(page),
    [page, data?.list],
  );

  const setFinalConfigList = async (args: any): Promise<void> => {
    const configList = await fetchOrFallbackConfigTabList(args, data);
    setFinalConfigData(configList);
  };

  const revalidateTabDataAndFetchConfigList = async (): Promise<void> => {
    const newCombo = await revalidateTabCombo(
      [unitURL, unit, device, user],
      tab,
      combo,
    );
    setFinalConfigList(prepareArguments(page, newCombo) as any);
  };

  React.useEffect(() => {
    isEquipment
      ? revalidateTabDataAndFetchConfigList()
      : setFinalConfigList(prepareArguments(page, combo) as any);
  }, [preparedArgs, page, data?.list]);

  const additionalProps = {
    textToDisplay: progressText(isDownloadInprogressInNetworks, data),
  };
  const [show, hide, updateProps] = useModal(
    DownloadInProgressModal,
    additionalProps,
  );

  if (
    isDownloadEnabledInNetworks &&
    downloadCompletionCallCount === fetchingCount
  ) {
    setDownloadComplete();
  }

  React.useEffect(() => {
    if (isDownloadEnabledInNetworks) {
      setDownloadCompletionCallCount(fetchingCount + 2);
      setPollingShown(false);
    }
  }, [isDownloadEnabledInNetworks]);

  const { data: pollingResponse } = useSWR<PollingStatusResponse>(
    () =>
      isDownloadPage && isDownloadEnabledInNetworks
        ? [xmlBackendURL, user, true]
        : null,
    readPollingSuspensionStatus,
    {
      refreshInterval: refreshFetchIntervalValue,
    },
  );

  React.useEffect(() => {
    const startPollingAsync = async () => {
      if (startPolling) {
        await withErrorHandled(
          t,
          theme,
          setPollingToActive,
        )({ url: xmlBackendURL, user });
        setStartPolling(false);
      }
    };
    startPollingAsync();
  }, [startPolling]);

  if (
    isDownloadComplete &&
    !isDownloadEnabledInNetworks &&
    !isDownloadInprogressInNetworks &&
    +pollingData?.enabled &&
    !pollingShown
  ) {
    showPollingModal();
    setPollingShown(true);
  }

  React.useEffect(() => {
    if (menuId === TABLE_IDS.TIME) {
      mutate();
    }
  }, [menuId]);

  React.useEffect(() => {
    if (pollingResponse) {
      setPollingData(pollingResponse);
    }
  }, [pollingResponse]);

  React.useEffect(() => {
    if (isDownloadInprogressInNetworks) {
      show();
      updateProps(additionalProps);
    } else {
      hide();
    }
  }, [data, isDownloadInprogressInNetworks]);

  React.useEffect(() => {
    data && isDownloadEnabledInNetworks && setData(data);
    if (
      data &&
      isPaginationEnabledInUploadPage(menuId, data.multipage) &&
      +page === initialPage
    ) {
      onSetMultiOptionsList(data.list?.slice(0, multiOptionsListLength));
    }
  }, [data]);

  if (data && isPaginationEnabledInUploadPage(menuId, data.multipage)) {
    data.list = updateDataListWithMultiOptions(
      multiOptionsList,
      data.list,
      page,
    );
  }

  const prevMenuId = usePrevious(menuId);
  const prevValidation = usePrevious(isValidating);
  const isRequestFinished = !isValidating && prevValidation;

  if (isRequestFinished && prevMenuId === menuId && updateTime) {
    updateTime(menuId, Date.now());
  }

  const list = finalConfigData || fallbackData.list || [];
  const multipage = data?.multipage;
  const amountOfPages = React.useRef<number>();

  React.useEffect(() => {
    if (multipage) {
      amountOfPages.current = Number(multipage);
    }
  }, [multipage]);

  const createArrayOfPages = React.useCallback(
    numOfPages => createSequenceFromNumber(numOfPages),
    [amountOfPages],
  );

  const getFullList = (listOfPages: string[]) =>
    listOfPages.map(pageNum => prepareArguments(pageNum));

  const pages = searchMode && createArrayOfPages(amountOfPages.current);
  React.useEffect(() => {
    if (data && multipage && !isEquipment) {
      updatePageData();
      const currentMultipage = Number(multipage);

      if (currentMultipage === amountOfPages.current) {
        amountOfPages.current = currentMultipage;
      }
    }
  }, [data, page]);

  const fetchAllPages = async listOfRequests => {
    const response = await Promise.allSettled(
      listOfRequests.map(options => fetchConfigurationTabByUnit(options)),
    );

    return (
      response
        .filter(promise => promise.status === 'fulfilled')
        // @ts-ignore
        .map(promise => promise.value.list)
        .flat()
    );
  };

  const { data: allPagesData = [], isLoading: isAllPagesDataLoading } = useSWR(
    pages ? getFullList(pages) : null,
    fetchAllPages,
  );

  const searchedData: ConfigurationListItem[] = React.useMemo(() => {
    // this logic works only for history -> sensors table since only here we have a searchbar
    // once we need to extend search to other pages, I suggest to create a polymorphic function
    // processing table data depending on table index

    if (!allPagesData.length) return [];
    if (!searchValue) {
      // remove text duplicates
      const firstThreeLinesOnEveryPage = ['0', '1', '2'];
      return allPagesData.filter(
        (item, index) =>
          !(index > 2 && firstThreeLinesOnEveryPage.includes(item.li)),
      );
    }

    let lastFoundIndex = null;

    const filteredItems = allPagesData.filter(({ devicetype, name }, index) => {
      if (devicetype && name.toLowerCase().includes(searchValue)) {
        lastFoundIndex = index;
        return true;
      }

      return lastFoundIndex !== null && lastFoundIndex + 1 === index;
    });

    return filteredItems;
  }, [searchValue]);

  const addresses = React.useMemo(
    () => getAddresses(processedList),
    [processedList],
  );

  useDeepCompareEffect(() => {
    onSetConfigurationDataItem(list);
    !openModals.length && setProcessedList(list);
  }, [list]);
  const handleSave = async (
    listItem,
    {
      value = null,
      ival = null,
      fval = null,
      valueProperty = null,
      tmpValue = null,
    } = {},
  ) => {
    if (isDownloadPage && data.list[0].li === listItem.li) {
      setPollingShown(true);
      setIsDownloadEnabledInNetworks(true);
    }
    const res = await withErrorHandled(t, theme, writeConfigurationListItem)(
      getUnitUrl(xmlBackendURL, unit),
      user,
      getAuditNames(list, +listItem.li, tab?.label),
      menuId,
      device,
      deviceGroup,
      configuretype,
      listItem,
      value,
      ival,
      fval,
      undefined,
      undefined,
      combo,
      arg1,
    );
    const { ival: iVal = HomeKey.Home, archive_files } = res || {};
    tabId === TABLE_ADDRESS.HOME_SCREEN &&
      dispatch({
        type: 'SET_PREFERRED_URL',
        payload: iVal,
      });

    // display updated value in the list between write_list and read_list commands
    updateListInSWRCache(data, listItem.li, valueProperty, tmpValue, mutate);

    const maskedPassword: string = value?.replace(/./g, '*');
    const isPasswordUpdatedForCurrentUser = checkPasswordUpdatedForCurrentUser(
      listItem,
      list,
      maskedPassword,
      user,
    );

    if (isPasswordUpdatedForCurrentUser) {
      onPasswordUpdatedForCurrentUser(isPasswordUpdatedForCurrentUser, value);
    }
    if (tabId === TABLE_ADDRESS.HISTORY_ARCHIVE && archive_files) {
      await downloadHistoryArchive([
        archive_files,
        user,
        getUnitUrl(xmlBackendURL, unit),
        unit,
        t,
      ]);
    }
    return res;
  };

  const resetConfigSwrCache = () => {
    mutate({} as ConfigurationTabByUnitResponse);
  };

  const noFilteredItems =
    searchMode && !isAllPagesDataLoading && !searchedData.length;

  const isLoading =
    isAllPagesDataLoading || (isValidating && !processedList?.length);

  const auditTableData = isAuditTrail && prepareAuditTableData(list);

  const auditTrailtableColumns = [
    {
      title: t('t3183'),
      dataIndex: 'eventId',
      ellipsis: true,
    },
    {
      title: t('t3184'),
      dataIndex: 'eventTime',
      ellipsis: true,
    },
    {
      title: t('t3185'),
      dataIndex: 'eventRole',
      ellipsis: true,
    },
    {
      title: t('t3186'),
      dataIndex: 'eventInfo',
      ellipsis: true,
    },
  ];

  const renderAuditInfoContent = !isLoading ? (
    <ContainerDimensions>
      {({ width, height }) => (
        <Div
          testId="audit-trail-content"
          style={{
            height,
            padding: `${theme.spacing.sm}px`,
          }}
        >
          <TableVirtualizedInfinite
            hasNextPage={false}
            isNextPageLoading={true}
            totalCount={auditTableData.length}
            minimumBatchSize={500}
            onLoadNextPage={null}
            rowKey={(record: any, index) => {
              return `${record?.eventId}-${index}`;
            }}
            scroll={{ y: height - theme.spacing.xxlg * 2, x: width }}
            columns={auditTrailtableColumns}
            dataSource={auditTableData}
            emptyPlaceholder={<ContentEmpty title={t('t33')} />}
          />
        </Div>
      )}
    </ContainerDimensions>
  ) : null;

  return noFilteredItems ? (
    <EmptyState
      title={t('t3341')}
      size="small"
      styles={{ root: { p: theme.spacing.md } }}
    />
  ) : isLoading ? (
    <Div testId="item-content-spinner">
      <ContentSpinner size={1} transparent={true} />
    </Div>
  ) : isAuditTrail ? (
    renderAuditInfoContent
  ) : (
    <ConfigurationItemNonVirtualizedList
      menuId={menuId}
      isUpdating={isValidating}
      isFailed={!!error}
      list={searchMode ? searchedData : processedList}
      getItemIsAuthorized={getItemIsAuthorized}
      addresses={addresses}
      unit={unit}
      tabIsEditable={tabIsEditable}
      onSave={handleSave}
      itemRenderer={itemRenderer}
      resetConfigSwrCache={resetConfigSwrCache}
    />
  );
}

export { ConfigurationItemContentList };
