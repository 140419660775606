import { User } from '@danfoss/etui-sm-xml';
import { TFunction } from 'i18next';
import { exportFilesAsZip } from 'utils/export-files';
import { delay } from '../utils/delay';
import { getLocalGraphicFiles } from './get-local-graphic-files';

export const exportLocalGraphicFiles = async (
  user: User,
  url: string,
  zipFileName: string,
  fileNames: string[],
  isSM800A: boolean,
  t: TFunction,
  singleFileExport: boolean = false,
) => {
  const graphicFileContents = [];
  try {
    await fileNames.reduce(async (promise, fileName) => {
      await promise;
      !isSM800A && (await delay(5000));
      const graphicFileContent = await getLocalGraphicFiles([
        user,
        url,
        fileName,
        isSM800A,
      ]);
      graphicFileContents.push(graphicFileContent);
    }, Promise.resolve());
    return await exportFilesAsZip(fileNames, graphicFileContents, zipFileName);
  } catch (e) {
    if (singleFileExport) {
      throw new Error(t('t3538'));
    } else {
      throw new Error(t('t3489'));
    }
  }
};
