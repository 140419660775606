import * as React from 'react';
import {
  ConfigurationListItem as ConfigurationListItemProp,
  Unit,
} from '@danfoss/etui-sm-xml';
import { useTheme } from '@danfoss/etui-system';
import { Div } from '@danfoss/etui-system-elements';
import { ellipsis } from '@danfoss/etui-core';
import { useParams } from 'react-router-dom';
import { ConfigurationListItemEditText } from './ConfigurationListItemEditText';
import { ConfigurationListItemEditNumber } from './ConfigurationListItemEditNumber';
import { ConfigurationListItemEditSelect } from './ConfigurationListItemEditSelect';
import { ConfigurationListItemEditAuth } from './ConfigurationListItemEditAuth';
import { ConfigurationListItemEditCheckList } from './ConfigurationListItemEditCheckList';
import { ConfigurationListItemEditTime } from './ConfigurationListItemEditTime';
import {
  ConfigurationListItemEditSaveModal,
  ConfigurationListItemEditSaveModalProps,
} from './ConfigurationListItemEditSaveModal';
import { ConfigurationListItemEditDateTime } from './ConfigurationListItemEditDateTime';
import { ConfigurationListItemEditAddress } from './ConfigurationListItemEditAddress';
import { ConfigurationListItemAddress } from './ConfigurationItemContentList';
import { ConfigurationListItemEditToggle } from './ConfigurationListItemEditToggle';
import { ConfigurationListItemEditInModal } from './ConfigurationListItemEditInModal';
import { ConfigurationListItemEditAction } from './ConfigurationListItemEditAction';
import { ConfigurationListItemEditBrowse } from './ConfigurationListItemEditBrowse';
import * as S from './styles';
import { ConfigurationListItemEditStagingPatternStep } from './ConfigurationListItemEditStagingPatternStep';
import { EditType } from './types';

export type SaveFunc = (
  item: ConfigurationListItemProp,
  newValue?: any, // TODO explore possible values
) => Promise<any>;

export interface BaseEditProps {
  item: ConfigurationListItemProp;
  type?: string;
  index?: number;
  name?: string;
  editMode?: string[];
  isAuthorized?: boolean;
  isSearchable?: boolean;
  isEditable?: boolean;
  autoFocus?: boolean;
  onSave: SaveFunc;
  onUnsavedChanges?: (options: ConfigurationListItemEditSaveModalProps) => void;
  onEnableEdit?: (name: string) => void;
  onCancel?: (fieldName?: string) => void;
  mutateList?: () => void;
  addonAnimatonDuration?: number;
  isConfirmModalOpen?: boolean;
  pathname?: string;
  unit?: Unit;
}

export type ConfigurationListItemEditProps = {
  index: number;
  id: string;
  item: ConfigurationListItemProp;
  addresses: ConfigurationListItemAddress[];
  unit: Unit;
  editType: EditType;
  isEditable: boolean;
  isAuthorized: boolean;
  isSearchable?: boolean;
  isTextOnly?: boolean;
  isFilelist?: boolean;
  onSave: SaveFunc;
  prefix?: string;
  resetConfigSwrCache?: () => void;
  isCopyControllerSelected?: boolean;
  isCopyWizard?: boolean;
  handleBrowse?: (file: File, item?: ConfigurationListItemProp) => void;
  isCopyLoading?: boolean;
  isWizards?: boolean;
  pathname?: string;
};

const MENUS_WITH_REMOVE_BTN = ['internetfiles', 'devicefiles'];
const ADDON_ANIMATION_TIME = 3000;

function ConfigurationListItemEdit({
  index,
  item,
  addresses,
  unit,
  onSave,
  id,
  editType,
  isSearchable,
  isEditable,
  isAuthorized,
  isTextOnly = false,
  isFilelist = false,
  resetConfigSwrCache,
  isCopyControllerSelected,
  handleBrowse,
  isCopyWizard,
  isCopyLoading,
  isWizards,
  pathname,
}: ConfigurationListItemEditProps) {
  const { value } = item;
  const theme = useTheme();
  const { menuId } = useParams<{ menuId: string }>();
  const [editMode, setEditMode] = React.useState<string[]>([]);
  const onEnableEditMode = (name: string) => {
    if (isAuthorized && !editMode.length) {
      setEditMode([name]);
    }
  };
  const onDisableEditMode = () => setEditMode([]);

  const [saveModalProps, setSaveModalProps] =
    React.useState<ConfigurationListItemEditSaveModalProps>({
      isOpen: false,
      onSaveChanges: () => ({}),
      onCancelChanges: () => ({}),
    });

  const showRemoveBtn =
    isFilelist && MENUS_WITH_REMOVE_BTN.some(menu => menuId.includes(menu));

  const handleOpenSaveModal = ({
    isOpen,
    onSaveChanges,
    onCancelChanges,
  }: ConfigurationListItemEditSaveModalProps) => {
    setSaveModalProps({
      isOpen,
      onSaveChanges,
      onCancelChanges,
    });
  };

  const handleCloseSaveModal = () => {
    setSaveModalProps({
      ...saveModalProps,
      isOpen: false,
    });
  };

  const renderRemoveActionButton = () => (
    <Div maxWidth="140px">
      <ConfigurationListItemEditAction
        pathname={pathname}
        item={item}
        onSave={onSave}
        isAuthorized={isAuthorized}
        isEditable={isEditable}
        removeBtn={true}
      />
    </Div>
  );

  const renderConfigurationListItemEdit = () => {
    if (showRemoveBtn) return renderRemoveActionButton();

    switch (editType) {
      case isEditable && EditType.Select:
        return (
          <ConfigurationListItemEditSelect
            isSearchable={isSearchable}
            index={index}
            name={id}
            item={item}
            onSave={onSave}
            addonAnimatonDuration={ADDON_ANIMATION_TIME}
            isAuthorized={isAuthorized}
            isFilelist={isFilelist}
            isCopyControllerSelected={isCopyControllerSelected}
            isCopyLoading={isCopyLoading}
            isWizards={isWizards}
          />
        );
      case isEditable && EditType.Text:
      case isEditable && EditType.Password:
      case isEditable && item.s_a !== '0' && EditType.Address:
        return (
          <ConfigurationListItemEditText
            type={editType}
            index={index}
            name={id}
            item={item}
            onSave={onSave}
            onUnsavedChanges={handleOpenSaveModal}
            isConfirmModalOpen={saveModalProps.isOpen}
            isAuthorized={isAuthorized}
            addonAnimatonDuration={ADDON_ANIMATION_TIME}
            addresses={addresses}
          />
        );
      case isEditable && EditType.Permissions:
        return (
          <ConfigurationListItemEditAuth
            item={item}
            isAuthorized={isAuthorized}
            onSave={onSave}
            onCancel={onDisableEditMode}
          />
        );
      case isEditable && EditType.CheckList:
        return (
          <ConfigurationListItemEditCheckList
            item={item}
            isAuthorized={isAuthorized}
            onSave={onSave}
            onCancel={onDisableEditMode}
          />
        );
      case isEditable && EditType.Num:
      case isEditable && EditType.FloatNum:
        return (
          <ConfigurationListItemEditNumber
            index={index}
            name={id}
            item={item}
            editType={editType}
            onSave={onSave}
            onUnsavedChanges={handleOpenSaveModal}
            isConfirmModalOpen={saveModalProps.isOpen}
            isAuthorized={isAuthorized}
            addonAnimatonDuration={ADDON_ANIMATION_TIME}
          />
        );
      case isEditable && EditType.Time:
        return (
          <ConfigurationListItemEditTime
            index={index}
            name={id}
            item={item}
            editMode={editMode}
            onSave={onSave}
            onUnsavedChanges={handleOpenSaveModal}
            onEnableEdit={onEnableEditMode}
            onCancel={onDisableEditMode}
            isAuthorized={isAuthorized}
            addonAnimatonDuration={ADDON_ANIMATION_TIME}
          />
        );
      case isEditable && EditType.DateTime:
        return (
          <ConfigurationListItemEditDateTime
            unit={unit}
            name={id}
            item={item}
            isAuthorized={isAuthorized}
            autoFocus={true}
            onSave={onSave}
          />
        );
      case isEditable && EditType.Toggle:
        return (
          <ConfigurationListItemEditToggle
            name={id}
            item={item}
            isAuthorized={isAuthorized}
            onSave={onSave}
          />
        );
      case isEditable && EditType.Address:
        return (
          <ConfigurationListItemEditAddress
            item={item}
            addresses={addresses}
            unit={unit}
            onSave={onSave}
            resetConfigSwrCache={resetConfigSwrCache}
          />
        );
      case EditType.ModalSubView:
        return <ConfigurationListItemEditInModal item={item} unit={unit} />;
      case EditType.Browse:
        return (
          <ConfigurationListItemEditBrowse
            handleBrowse={handleBrowse}
            isCopyWizard={isCopyWizard}
            item={item}
          />
        );
      case isEditable && EditType.StagingPatternStep:
        return <ConfigurationListItemEditStagingPatternStep item={item} />;
      case EditType.None:
      default:
        return (
          <Div display="flex" color={theme.palette.text.primary}>
            <Div title={value} {...(ellipsis() as any)}>
              <S.PreWrapper>
                <pre>{value}</pre>
              </S.PreWrapper>
            </Div>
          </Div>
        );
    }
  };

  const fileListTitle = isFilelist && item.li === '0';

  return isTextOnly || fileListTitle ? null : (
    <S.ListEditItemWrapper
      {...(ellipsis() as any)}
      width={['75%', '100%', '100%', '100%']}
    >
      {renderConfigurationListItemEdit()}
      {saveModalProps.isOpen ? (
        <ConfigurationListItemEditSaveModal
          onClose={handleCloseSaveModal}
          item={item}
          {...saveModalProps}
        />
      ) : null}
    </S.ListEditItemWrapper>
  );
}

export { ConfigurationListItemEdit };
