import { Unit, UnitNetwork, XML_ACTION } from '@danfoss/etui-sm-xml';
import {
  fetchDeviceParameters,
  fetchGenericDevices,
} from '../components/DeviceParameterModal/actions';

export async function getGenericDeviceParametersForHost(
  url,
  unitNetwork,
  user,
  nodes,
) {
  const genericDevices = await fetchGenericDevices(url, user);

  const deviceParams = [];
  await genericDevices
    ?.filter(device => (nodes ? nodes.includes(device.node) : true))
    .reduce(async (promise, device: any) => {
      await promise;
      const deviceParam = await fetchDeviceParameters(
        url,
        user,
        XML_ACTION.READ_GENERIC,
        unitNetwork,
        device,
      );
      deviceParams.push(deviceParam);
    }, Promise.resolve());
  return deviceParams?.flat() ?? [];
}

export async function getAllGenericParameters(
  units,
  user,
  getFirstValidUrl: (unit: Unit) => string,
  getFirstValidUnitNetwork: (unit: Unit) => UnitNetwork,
  nodes?: string[],
) {
  const genericParameters = units.map(async unit => {
    return getGenericDeviceParametersForHost(
      getFirstValidUrl(unit),
      getFirstValidUnitNetwork(unit),
      user,
      nodes,
    );
  });
  const result = await Promise.all(genericParameters);
  return result?.flat() ?? [];
}
