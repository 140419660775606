import { SelectedView } from '../types/LocalGraphics.types';

export const getSupportedFileFormatsByView = (
  selectedView: SelectedView,
  isSm800A: boolean,
): string =>
  selectedView ===
  (SelectedView.REMOTE_VIEW_CLOUD || SelectedView.REMOTE_VIEW_SM)
    ? '.png,.jpg,.jpeg,.gif,.svg' // background image formats
    : isSm800A
    ? '.png, .vz2' // svb5 - SM800A
    : '.bmp, .viz'; // rmt - SM800

export const getIsFileFormatSupported = (
  uploadedFile: File,
  fileFormatsSupported: string,
) => {
  let isSupported = false;
  const formatsSupported = fileFormatsSupported?.split(',');
  formatsSupported?.every(formatSupported => {
    if (uploadedFile?.name?.endsWith(formatSupported.trim())) {
      isSupported = true;
      return false;
    }
    return true;
  });
  return isSupported;
};

export const getIsFileDimensionSupported = (
  isSm800A: boolean,
  width: number,
  height: number,
) =>
  isSm800A
    ? width <= MAX_DIMENSION.Vz2MaxWidthPx &&
      height <= MAX_DIMENSION.Vz2MaxHeightPx
    : width <= MAX_DIMENSION.VizMaxWidthPx &&
      height <= MAX_DIMENSION.VizMaxHeightPx;

export enum MAX_DIMENSION {
  Vz2MaxWidthPx = 800, // PNG
  Vz2MaxHeightPx = 450,
  VizMaxWidthPx = 792, // BMP
  VizMaxHeightPx = 548,
}

export enum BMP_IMAGE_OFFSET {
  Size = 0x02, // PNG
  Offset = 0x0a,
  HeaderSize = 0x0e, // BMP
  BMPWidth = 0x12,
  BMPHeight = 0x16,
  Bpp = 0x1c,
  Comp = 0x1e,
  Colors = 0x2e,
  InvalidHeaderSize = 0x28,
  ValidBMPImageByte1 = 66,
  ValidBMPImageByte2 = 77,
}

export function readImageFile(file: File): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

export const getMaxDimension = (isSm800A: boolean) => {
  const maxWidthAllowed = isSm800A
    ? MAX_DIMENSION.Vz2MaxWidthPx
    : MAX_DIMENSION.VizMaxWidthPx;

  const maxHeightAllowed = isSm800A
    ? MAX_DIMENSION.Vz2MaxHeightPx
    : MAX_DIMENSION.VizMaxHeightPx;

  return {
    maxWidthAllowed,
    maxHeightAllowed,
  };
};

export const COLOR_DEPTH = 8;

export const MAX_CHUNK_SIZE_GRAPHIC_SM800A = 2457600;

export const MAX_CHUNK_SIZE_GRAPHIC_SM800 = 61440; // 102400; 434791 61440

export const getIsVz2File = (inputFile: File) =>
  inputFile?.name?.endsWith('.vz2');

export const getIsBmpFile = (inputFile: File) =>
  inputFile?.name?.endsWith('.bmp');

export const getIsPNGFile = (inputFile: File) =>
  inputFile?.name?.endsWith('.png');

export const getIsVizFile = (inputFile: File) =>
  inputFile?.name?.endsWith('.viz');

export function readFileAsync(
  imgFile: File,
): Promise<{ base64: string | ArrayBuffer; width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const image = new Image();
      image.onload = () => {
        const { width, height } = image;
        resolve({ base64: reader.result, width, height });
      };
      image.src = e.target.result;
    };

    reader.onerror = reject;
    reader.readAsDataURL(imgFile);
  });
}

export function readXmlFileAsync(
  imgFile: File,
): Promise<{ base64: string | ArrayBuffer; width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve({ base64: reader.result, width: 0, height: 0 });
    };

    reader.onerror = reject;
    reader.readAsText(imgFile);
  });
}
