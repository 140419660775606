import {
  BMP_HEADER_SIZE,
  BMP_HEIGHT,
  BMP_WIDTH,
} from '../types/LocalGraphics.types';
import { createImgSourceFromBytes } from './create-image-source-from-bytes';

export const showBitMapImage = (imageByteArray: Uint8Array) => {
  const finalBmpImage = new Uint8Array(BMP_HEADER_SIZE + imageByteArray.length);
  const view = new DataView(finalBmpImage.buffer);
  // File Header

  // BM magic number.
  view.setUint16(0, 0x424d, false);
  // File size.
  view.setUint32(2, finalBmpImage.length, true);
  // Offset to image data.
  view.setUint32(10, BMP_HEADER_SIZE, true);

  // BITMAPINFOHEADER

  // Size of BITMAPINFOHEADER
  view.setUint32(14, 40, true);
  // Width
  view.setInt32(18, BMP_WIDTH, true);
  // Height (signed because negative values flip
  // the image vertically).
  view.setUint32(22, BMP_HEIGHT, true);
  // Number of colour planes (colours stored as
  // separate images; must be 1).
  view.setUint16(26, 1, true);
  // Bits per pixel.
  view.setUint16(28, 32, true);
  // Compression method, 0 = BI_RGB
  view.setUint32(30, 0, true);
  // Image size in bytes.
  view.setUint32(34, imageByteArray.length, true);
  // Horizontal resolution, pixels per metre.
  // This will be unused in this situation.
  view.setInt32(38, 0, true);
  // Vertical resolution, pixels per metre.
  view.setInt32(42, 0, true);
  // Number of colours. 0 = all
  view.setUint32(46, 0, true);
  // Number of important colours. 0 = all
  view.setUint32(50, 0, true);

  finalBmpImage.set(imageByteArray, 54);

  const bmpImageSource = createImgSourceFromBytes(finalBmpImage, 'bmp');

  return { bmpImageSource, finalBmpImage };
};
