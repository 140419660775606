import { Unit, UnitNetwork, User, XML_ACTION } from '@danfoss/etui-sm-xml';
import { fetchDeviceParameters } from '../components/DeviceParameterModal/actions';

export async function fetchAllAkioParameters(
  units: Unit[],
  user: User,
  getFirstValidUrl: (unit: Unit) => string,
  getFirstValidUnitNetwork: (unit: Unit) => UnitNetwork,
) {
  const akioParams = [];
  await units.reduce(async (promise, unit) => {
    await promise;
    const relays = await fetchDeviceParameters(
      getFirstValidUrl(unit),
      user,
      XML_ACTION.READ_RELAYS,
      getFirstValidUnitNetwork(unit),
    );
    akioParams.push(relays);
    const meters = await fetchDeviceParameters(
      getFirstValidUrl(unit),
      user,
      XML_ACTION.READ_METERS,
      getFirstValidUnitNetwork(unit),
    );
    akioParams.push(meters);
    const inputs = await fetchDeviceParameters(
      getFirstValidUrl(unit),
      user,
      XML_ACTION.READ_INPUTS,
      getFirstValidUnitNetwork(unit),
    );
    akioParams.push(inputs);
    const sensors = await fetchDeviceParameters(
      getFirstValidUrl(unit),
      user,
      XML_ACTION.READ_SENSORS,
      getFirstValidUnitNetwork(unit),
    );
    akioParams.push(sensors);
    const varouts = await fetchDeviceParameters(
      getFirstValidUrl(unit),
      user,
      XML_ACTION.READ_VAR_OUTS,
      getFirstValidUnitNetwork(unit),
    );
    akioParams.push(varouts);
  }, Promise.resolve());

  const allAkioParamsFlat = akioParams
    .filter(akioParam => akioParam !== undefined)
    .flat();
  return allAkioParamsFlat;
}
