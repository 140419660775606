export function getCurrentDateTime(): {
  date: Date;
  time: string;
  hours: string;
  minutes: string;
  seconds: string;
  dateIsoString: string;
} {
  const date = new Date();
  const dateIsoString = date.toISOString().split('T')[0].replace(/-/g, '');
  const hours = `${date.getHours()}`.padStart(2, '0');
  const minutes = `${date.getMinutes()}`.padStart(2, '0');
  const seconds = `${date.getSeconds()}`.padStart(2, '0');
  const time = `${hours}:${minutes}`;

  return { date, time, hours, minutes, seconds, dateIsoString };
}
