import {
  fetchConfigurationTableByUnit,
  Unit,
  User,
  ConfigurationTabItem,
  ConfigurationListItem,
  fetchXMLData,
  XML_ACTION,
  Device,
  fetchConfigurationListByUnit,
  ConfigurationListDataArray,
  fetchDevicesByUnit,
  XML_DEVICE_LIST,
  PATHNAMES,
  XML_DEVICE_COMBO,
} from '@danfoss/etui-sm-xml';
import { fetchDeviceConfigurationByUnit } from 'pages/ConfigurationPage/DeviceConfiguration';
import { getArray, getTabValues, TABLE_ADDRESS } from '../utils';
import {
  getDeviceTypeForListData,
  isListTypeData,
} from '../utils/get-device-type-for-list-data';
import { NodeTypes } from '../components/ConfigurationItemContentList/utils/address-utils';
import {
  BasicRequestArgs,
  PollingStatusResponse,
} from '../components/ConfigurationItemContentList/types';

async function fetchConfigurationTabsByUnit([
  sourceUrl,
  unit,
  tablename,
  tableaddress,
  user,
]: [string, Unit, string, string, User]): Promise<ConfigurationTabItem[]> {
  try {
    const data = await fetchConfigurationTableByUnit([
      sourceUrl,
      unit,
      tablename,
      tableaddress,
      user,
    ]);

    if (data?.items?.tabitem) {
      return getArray(data.items.tabitem).map((item: ConfigurationTabItem) => {
        return {
          ...item,
          id: `${tableaddress}-${item.index}`,
        };
      });
    }

    return [];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return [];
  }
}

export type ConfigurationTabByUnitResponse = {
  tabs: ConfigurationTabItem[];
  subtabs: ConfigurationTabItem[];
  list: ConfigurationListItem[];
  groupnames: string[];
  subgroupnames: string[];
  multipage: string;
};

async function fetchConfigurationTabByUnit([
  sourceUrl,
  unit,
  tabId,
  user,
  cachedDevices = {},
  skipSessionUpdate = false,
  useparent = null,
  isconfigure = null,
  bpidx = null,
  stype = null,
  nodetype = null,
  node = null,
  group = null,
  subgroup = null,
  page = null,
  combo = null,
  configuretype = null,
  arg1 = null,
  arg2 = null,
  arg3 = null,
  isOnline = null,
  old_cfgtype = null,
  listtabname = null,
  pnum = null,
  parentpnum = null,
  listindex = null,
  parentlistindex = null,
  bnum = null,
  parentbnum = null,
  parentgroup = null,
  calptindex = null,
  listgroup = null,
  listcombo = null,
]): Promise<ConfigurationTabByUnitResponse> {
  const { tableAddress, tableIndex } = getTabValues(tabId);

  const data = await fetchConfigurationListByUnit(
    sourceUrl,
    unit,
    tableAddress,
    tableIndex,
    user,
    useparent,
    isconfigure,
    bpidx,
    stype,
    nodetype,
    node,
    group,
    subgroup,
    page,
    combo,
    configuretype,
    arg1,
    arg2,
    arg3,
    old_cfgtype,
    listtabname,
    pnum,
    parentpnum,
    listindex,
    parentlistindex,
    bnum,
    parentbnum,
    parentgroup,
    calptindex,
    listgroup,
    listcombo,
    skipSessionUpdate,
  );

  let tabs: ConfigurationTabItem[] = [];
  let list: ConfigurationListItem[] = [];
  let groupnames: string[] = [];
  let subgroupnames: string[] = [];
  let listdata: ConfigurationListItem['listdata'] = [];
  let dataTabs: ConfigurationTabItem[] = null;
  let dataSubTabs: ConfigurationTabItem[] = null;
  let subtabs: ConfigurationTabItem[] = [];

  if (data?.items?.tabitem) {
    dataTabs = getArray<ConfigurationTabItem>(data.items.tabitem);
  }

  if (data?.tabs?.items?.tabitem) {
    dataSubTabs = getArray<ConfigurationTabItem>(data.tabs.items.tabitem);
  }

  if (dataTabs) {
    tabs = dataTabs.map(item => {
      const tableId = Array.isArray(data.tableaddress)
        ? data.tableaddress[1]
        : data.tableaddress;
      return { ...item, id: `${tableId}-${item.index}` };
    });
  }

  if (dataSubTabs) {
    subtabs = dataSubTabs.map(item => {
      const tableId = data?.tabs?.tableaddress;
      return { ...item, id: `${tableId}-${item.index}` };
    });
  }

  if (data?.items?.l) {
    list = getArray<ConfigurationListItem>(data.items.l);
  }

  if (data?.groupnames) {
    groupnames = getArray<string>(data.groupnames.groupname);
  }

  if (data?.subgroupnames) {
    subgroupnames = getArray<string>(data.subgroupnames.groupname);
  }

  if (data?.listgroup) {
    listdata = getArray<any>(data.listgroup.listdata as any);
  }

  for (const listItem of list) {
    if (isOnline !== null) {
      listItem.online = isOnline;
    }
  }

  const deviceList = {};

  for (const listItem of list) {
    if (
      listItem.devicetype &&
      listItem.listdata === undefined &&
      !isListTypeData(Number(listItem.devicetype))
    ) {
      const deviceType = Number(listItem.devicetype);
      const cacheKey = deviceType + unit.unit_addr;

      if (!(cacheKey in cachedDevices || cacheKey in deviceList)) {
        deviceList[cacheKey] = getDeviceList(
          deviceType,
          sourceUrl,
          unit,
          user,
        ).then(deviceListData => {
          deviceList[cacheKey] = deviceListData;
          cachedDevices[cacheKey] = deviceListData;
        });
      }
    }
  }
  await Promise.all(Object.values(deviceList));

  return {
    tabs,
    subtabs,
    list: list.map(listItem => ({
      ...listItem,
      arg1,
      arg2,
      arg3,
      listdata: listItem.listdata
        ? getArray(listItem.listdata as unknown as ConfigurationListDataArray)
        : listItem.devicetype + unit.unit_addr in cachedDevices
        ? getArray(cachedDevices[listItem.devicetype + unit.unit_addr])
        : (listdata as ConfigurationListDataArray).length !== 0
        ? (listdata as ConfigurationListDataArray)
        : [],
    })),
    multipage: data.multipage || '0',
    groupnames,
    subgroupnames,
  };
}

async function getDeviceList(deviceType: number, sourceUrl, unit, user) {
  const type: XML_DEVICE_LIST = getDeviceTypeForListData(deviceType);
  if (type !== null) {
    const data: any = await fetchDevicesByUnit(sourceUrl, unit, type, user);
    return data?.dt?.ld;
  }
  return null;
}

async function refreshDeviceSettingsValues(
  device: Device,
  user: User,
  url: string,
) {
  const {
    bpidx: deviceBpidx,
    arg1 = '0',
    arg2 = '0',
    arg3 = '0',
    nodetype = '0',
    combo = '0',
  } = device || {};

  const bpidx = deviceBpidx ? parseInt(device.bpidx, 10) - 1 : '0';

  const attributes = {
    action: XML_ACTION.SET_DIALOGBOX,
    version: 'C',
    storeview_only: '1',
    lang: user.language,
    user: user.user,
    password: user.password,
    set: '0',
    value: '1',
    nodetype,
    combo,
    arg1,
    arg2,
    arg3,
    bpidx,
    type: '1',
  };

  return fetchXMLData({
    url,
    attributes,
  });
}

async function writeConfigurationListItem(
  url: string,
  user: User,
  audit: Record<'audit1' | 'audit2' | 'audit3' | 'audit4', string>,
  menuId: string,
  device: Device,
  deviceGroup: string,
  configuretype: string = '0',
  item: any, // todo
  value: any, // todo
  ival: any, // todo
  newFval: any = null, // todo
  parent: any = {}, // same as item
  useparent: '0' | '1' = '0',
  combo: XML_DEVICE_COMBO | string = null,
  foodGroupIndex: string = null,
) {
  const [tableaddress, index] = menuId.split('-');
  const {
    ef: enterfunc,
    width,
    pnum,
    bnum,
    over,
    type,
    dom: domain,
    units,
    token,
    fVal: fval,
    authtype,
    node,
    mod,
    point,
    iotype,
    nodetype: nType = null,
  } = item;
  const {
    bpidx: deviceBpidx,
    arg2 = '0',
    arg3 = '0',
    stype = '',
  } = device || {};
  let { nodetype = '0', arg1 = '0' } = device || {};
  nodetype = nType || nodetype;

  if (!combo) {
    combo = device?.combo || '0';
  }
  nodetype =
    combo === XML_DEVICE_COMBO.COMBO_CPT ? NodeTypes.NODE_TYPE_NA : nodetype;
  arg1 = combo === XML_DEVICE_COMBO.COMBO_CPT ? foodGroupIndex : arg1;
  const bpidx = deviceBpidx ? parseInt(device.bpidx, 10) - 1 : '0';

  const {
    pnum: parentpnum,
    bnum: parentbnum,
    group: parentgroup,
    li: parentlistindex,
  } = parent;

  const attributes = {
    action: XML_ACTION.WRITE_LIST,
    tableaddress,
    index,
    useparent,
    lang: user.language,
    isconfigure: '1',
    version: 'C',
    storeview_only: '1',
    group: deviceGroup || '0',
    // from device start
    arg1,
    arg2,
    arg3,
    stype,
    nodetype,
    combo,
    configuretype,
    bpidx,
    // from device end
    // from item start
    enterfunc,
    width,
    pnum,
    bnum,
    over,
    type,
    domain,
    units,
    token,
    authtype,
    node,
    mod,
    point,
    iotype,
    // from item end
    user: user.user,
    password: user.password,
    ...audit,
    value: value || item.value,
    ival,
    fval: newFval === null ? fval : newFval,
    ...(useparent === '1' && {
      parentbnum,
      parentpnum,
      parentgroup,
      parentlistindex,
    }),
  };

  return fetchXMLData({
    url,
    attributes,
  });
}

async function fetchDeviceStatusTabData([
  sourceUrl,
  unit,
  device,
  user,
  selectedTab,
  pathname = null,
]: [string, Unit, Device, User, ConfigurationTabItem, string?]) {
  const { tableIndex } = getTabValues(selectedTab.id);

  if (
    selectedTab.id === TABLE_ADDRESS.SETTINGS_TAB &&
    +tableIndex &&
    pathname?.includes(PATHNAMES.EQUIPMENT)
  ) {
    try {
      await refreshDeviceSettingsValues(device, user, sourceUrl);
    } catch (e) {
      console.error(e);
    }
  }
  return fetchConfigurationTabByUnit([
    sourceUrl,
    unit,
    selectedTab.id,
    user,
    {},
    false, // skipSessionUpdate
    '0', // useparent
    '0', // isconfigure
    device && device.bpidx ? parseInt(device.bpidx, 10) - 1 : '0',
    device ? device.stype : null,
    device ? device.nodetype : null,
    device ? device.node : null,
    '0',
    '0',
    '0',
    selectedTab ? selectedTab.combo : device ? device.combo : '0',
    selectedTab?.configuretype || '0',
    device ? device.arg1 : null,
    device ? device.arg2 : null,
    device ? device.arg3 : null,
    device && device.online ? +device.online : null,
  ]);
}

async function readPollingSuspensionStatus([url, user, skipSessionUpdate]: [
  string,
  User,
  boolean,
]): Promise<PollingStatusResponse> {
  const attributes = {
    action: XML_ACTION.READ_POLLING_SUSPENSION,
    user: user.user,
    password: user.password,
    ...(skipSessionUpdate ? { session_update: 'no' } : {}),
  };

  return fetchXMLData({
    url,
    attributes,
  });
}

async function setPollingToActive({ url, user }: BasicRequestArgs) {
  const attributes = {
    action: XML_ACTION.WRITE_LIST,
    tableaddress: TABLE_ADDRESS.NETWORK_NODES_UPLOAD,
    index: '0',
    lang: user.language,
    isconfigure: '1',
    version: 'C',
    storeview_only: '1',
    enterfunc: '100277',
    user: user.user,
    password: user.password,
    ival: '0',
    fval: '0.00',
  };

  return fetchXMLData({
    url,
    attributes,
  });
}

async function fetchOrFallbackConfigTabList(
  args: any,
  data: ConfigurationTabByUnitResponse,
): Promise<ConfigurationListItem[]> {
  try {
    const originalData: ConfigurationTabByUnitResponse =
      await fetchConfigurationTabByUnit(args);

    const originalList = originalData?.list || [];
    const dataList = data?.list || [];
    return originalList.length !== dataList.length ? originalList : dataList;
  } catch {
    return data?.list || [];
  }
}

async function revalidateTabCombo(
  args: [string, Unit, Device, User],
  tab: ConfigurationTabItem,
  combo: '0' | XML_DEVICE_COMBO,
): Promise<XML_DEVICE_COMBO | '0'> {
  try {
    const deviceTabs = await fetchDeviceConfigurationByUnit(args);

    if (Array.isArray(deviceTabs) && deviceTabs.length > 1) {
      const tabs = deviceTabs.slice(1);
      const firstTabCombo = tabs[0]?.combo;
      return tab?.combo === firstTabCombo ? tab.combo : firstTabCombo || combo;
    }
    return combo;
  } catch {
    return combo;
  }
}

export {
  fetchConfigurationTabsByUnit,
  fetchConfigurationTabByUnit,
  writeConfigurationListItem,
  fetchDeviceStatusTabData,
  refreshDeviceSettingsValues,
  readPollingSuspensionStatus,
  setPollingToActive,
  fetchOrFallbackConfigTabList,
  revalidateTabCombo,
};
