/**
 * This file is imported from https://dev.azure.com/danfoss/AK-SM/_git/aksm.800a.release_files?path=%2Fhtml%2Fassets%2Fjs%2FimageFileParser_GFE.js&_a=contents&version=GBdevelop
 * The 'createImgBytesArray' is the only which was modified to simply parse from base64 string instead of reading from DB
 */
import { Unit } from '@danfoss/etui-sm-xml';
import { TFunction } from 'i18next';
import {
  BYTES_PER_POINT,
  LocalGraphicsVz2DataPoints,
} from '../types/LocalGraphics.types';
import { COLOR_DEPTH, MAX_DIMENSION } from './new-graphic-file-actions';

let projectDatapointsViz = [];

function addNewDataPointViz(item, index) {
  projectDatapointsViz.splice(index, 0, {
    name: item.name,
    host: item.host,
    iPointName: item.iPointName,
    iUnits: item.iUnits,
    iParameterName: item.iParameterName,
    iSize: item.iSize,
    nodetype: item.nodetype,
    node: item.node,
    mod: item.mod,
    point: item.point,
    parmIndex: item.parmIndex,
    x: item.x,
    y: item.y,
    generic_node: item.generic_node,
  });
}

function clearProjectDatapointsViz() {
  projectDatapointsViz = [];
}

function createImgBytesArray(
  b64FileVersion,
  callback: (array: Uint8Array) => void,
) {
  const raw = atob(b64FileVersion);
  const array = new Uint8Array(new ArrayBuffer(raw.length));
  for (let i = 0; i < raw.length; i++) {
    array[i] = raw.charCodeAt(i);
  }

  callback(array);
}

export const parseVizFile = (
  b64FileVersion,
  t: TFunction,
  currentUnit: Unit,
  callback: (
    image: Uint8Array,
    newVizDataPoints: LocalGraphicsVz2DataPoints[],
    array: Uint8Array,
  ) => void,
) => {
  // Open image as byte array
  createImgBytesArray(b64FileVersion, array => {
    const bmpWidthCorrected =
      Math.ceil((COLOR_DEPTH * MAX_DIMENSION.VizMaxWidthPx) / 32) * 4;
    const size = bmpWidthCorrected * MAX_DIMENSION.VizMaxHeightPx;
    const palPosition = 2 ** COLOR_DEPTH * 3;
    const pal = new Uint8Array(array.slice(0, palPosition)); // palette
    const pels = new Uint8Array(palPosition + size);
    const newVizDataPoints: LocalGraphicsVz2DataPoints[] = [];
    const pelsChunk = array.slice(palPosition, palPosition + size);
    pels.set(pelsChunk, palPosition);
    const pegPels = [];
    let bogus1 = palPosition;
    for (let i = 0; i < size; i++) {
      pegPels.push(pels[bogus1 + i]);
    }
    bogus1 = palPosition + size;
    let reamianingBytesAvailable = array.length - bogus1;
    let currentPosition = bogus1 + 4;
    const b1 = new Uint8Array(currentPosition);
    const b2 = new Uint8Array(4);
    if (reamianingBytesAvailable >= 4) {
      b1.set(array.slice(bogus1, currentPosition), bogus1);
      b2[0] = b1.at(-4);
      b2[1] = b1.at(-3);
      b2[2] = b1.at(-2);
      b2[3] = b1.at(-1);

      if (
        b2[0] !== 0xde ||
        b2[1] !== 0xad ||
        b2[2] !== 0xbe ||
        b2[3] !== 0xef
      ) {
        throw new Error(t('t3636'));
      }
      let total = 0;
      reamianingBytesAvailable = array.length - currentPosition;
      if (reamianingBytesAvailable > 0) {
        // datapoint logic comes here
        total = array.at(currentPosition);
        currentPosition += 1; // move 1 byte ahead to start datapoints part in viz file
        for (let j = 0; j < total; j++) {
          const dataPointEndIndex = currentPosition + BYTES_PER_POINT;
          const datapoint = new Uint8Array(
            array.slice(currentPosition, dataPointEndIndex),
          );
          const params: LocalGraphicsVz2DataPoints = {
            name: '',
            host: Number(currentUnit.unit_addr),
            iPointName: datapoint.at(0),
            iUnits: datapoint.at(1),
            iParameterName: datapoint.at(2),
            iSize: datapoint.at(3),
            nodetype: datapoint.at(4),
            node: datapoint.at(5),
            mod: datapoint.at(6),
            point: datapoint.at(7),
            parmIndex: (datapoint.at(8) << 8) + datapoint.at(9),
            x: (datapoint.at(10) << 8) + datapoint.at(11),
            y: (datapoint.at(12) << 8) + datapoint.at(13),
            generic_node: datapoint.at(5),
          };
          newVizDataPoints.push(params);
          currentPosition += BYTES_PER_POINT;
        }
      }
      const pels32List = [];
      // Convert from palette indices to 24-bit color for JavaScript
      // Also inverts the image
      // show the bitmap onscreen
      let c: number;
      let n: number;
      for (let i = 0; i < size; i++) {
        n = pegPels[i];
        c = (pal[n * 3] << 16) + (pal[n * 3 + 1] << 8) + pal[n * 3 + 2];
        const byte1 = 0xff & c;
        const byte2 = 0xff & (c >> 8);
        const byte3 = 0xff & (c >> 16);
        const byte4 = 0xff & (c >> 24);
        pels32List.push(byte1);
        pels32List.push(byte2);
        pels32List.push(byte3);
        pels32List.push(byte4);
      }

      const pels32 = new Uint8Array(pels32List);

      callback(pels32, newVizDataPoints, array);
    }
  });
};

export function parseVz2File(b64FileVersion, imageOnly, callback) {
  const imageStartByte = 21;
  let datapointsOffset;
  const ReservedSpace = 32;
  const pointBytesNumber = 14 + ReservedSpace;

  // Open image as byte array
  createImgBytesArray(b64FileVersion, array => {
    if (array === null || array === undefined) {
      callback(null);
    }
    // 1. Calculate image size
    const pngSize =
      (array[0] << 24) + (array[1] << 16) + (array[2] << 8) + array[3];

    // 2. Read image bytes
    const pngImageBytes = new Uint8Array(pngSize);
    for (let count = 0; count < pngSize; count++) {
      pngImageBytes[count] = array[count + imageStartByte];
    }

    // If we need only image bytes - return now
    if (imageOnly === true) callback(pngImageBytes);
    // 3. Check the file format (necessary to have "DEADDEAD" at the end)
    const vizVersionControl = new Array(4);
    for (let i = 0; i < 4; i++) {
      vizVersionControl[i] = array[i + imageStartByte + pngSize];
    }
    if (
      vizVersionControl[0] !== 0xde ||
      vizVersionControl[1] !== 0xad ||
      vizVersionControl[2] !== 0xbe ||
      vizVersionControl[3] !== 0xef
    ) {
      // Error: where's the beef!
      callback(null);
    }

    // 4. Read system datapoints
    datapointsOffset = imageStartByte + pngSize + 4 + 2;
    let total;
    if (array.length > datapointsOffset) {
      total =
        (array[imageStartByte + pngSize + 4] << 8) +
        array[imageStartByte + pngSize + 4 + 1];
    } else {
      total = 0;
    }
    clearProjectDatapointsViz();

    if (total >= 0) {
      for (let i = 0; i < total; i++) {
        const pointOffset = datapointsOffset + i * pointBytesNumber;
        const param = {
          name: '',
          host: array[pointOffset + 0],
          iPointName: array[pointOffset + 1],
          iUnits: array[pointOffset + 2],
          iParameterName: array[pointOffset + 3],
          iSize: array[pointOffset + 4],
          nodetype: array[pointOffset + 5],
          node: array[pointOffset + 6],
          mod: array[pointOffset + 7],
          point: array[pointOffset + 8],
          parmIndex: (array[pointOffset + 9] << 8) + array[pointOffset + 10],
          x: (array[pointOffset + 11] << 8) + array[pointOffset + 12],
          y: (array[pointOffset + 13] << 8) + array[pointOffset + 14],
          generic_node: array[pointOffset + 15],
        };
        addNewDataPointViz(param, projectDatapointsViz.length);
      }
    } else {
      // Error: wrong datapoints number
      callback(null);
    }

    callback(pngImageBytes, projectDatapointsViz);
  });
}
