export enum EditType {
  Action = 'action',
  Address = 'address',
  DateTime = 'date',
  FloatNum = 'float',
  ModalSubView = 'modal-sub-view',
  None = 'none',
  Num = 'number',
  Password = 'password',
  Permissions = 'permissions',
  Select = 'select',
  Text = 'text',
  Time = 'time',
  Toggle = 'toggle',
  CheckList = 'check-list',
  Browse = 'browse',
  StagingPatternStep = 'staging-pattern-step',
}
