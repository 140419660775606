import { User } from '@danfoss/etui-sm-xml';

export interface LocalGraphicsVizFileData {
  action?: string;
  error?: string;
  files?: LocalVizFile;
  flp_files?: string;
  lang?: string;
  max_flp?: string;
  max_viz?: string;
  nextflpfile?: NextFlpFile;
  nextvizfile?: NextVizFile;
  password?: string;
  processing?: string;
  storeview_only?: string;
  total_files?: string;
  total_flp_files?: string;
  user?: string;
  version?: string;
}

export interface SiteCheckBox {
  name: string;
  checked: boolean;
}

export interface LocalVizFile {
  file?: VizFile[];
}

export interface VizFile {
  description?: string;
  vizindex?: string;
  _?: string;
}

export interface NextFlpFile {
  flpoverwrite?: string;
  nextflpindex?: string;
  _?: string;
}

export interface NextVizFile {
  nextfileindex?: string;
  nextjpgfile?: string;
  overwrite?: string;
  _?: string;
}

export enum SelectedView {
  REMOTE_VIEW_CLOUD = '1',
  REMOTE_VIEW_SM = '2',
  LOCAL_VIEW = '3',
}
export enum RemoteView {
  SM_View = 'System Manager',
  Scada_View = 'Cloud',
}

export type ViewRadioButtonProps = {
  value: string;
  label: string;
  isDisabled: boolean;
  disableToolTip: boolean;
  errorMessage?: string;
};

export interface FileContent {
  size: string;
  offset: string;
  b64: string;
}

export interface LocalGraphicsActionModalProps {
  isOpen: boolean;
  onClose: () => void;
  file: VizFile;
  isXs: boolean;
  user: User;
  url: string;
  isSM800A: boolean;
}

export interface LocalGraphicsXmlMapping {
  xml: string;
  mappings: LocalGraphicsMapping[];
}

export interface LocalGraphicsMapping {
  cellId: string;
  paramId: string;
}

export interface LocalGraphicsVz2DataPoints {
  name: string;
  host: number;
  iPointName: number;
  iUnits: number;
  iParameterName: number;
  iSize: number;
  nodetype: number;
  node: number;
  mod: number;
  point: number;
  parmIndex: number;
  x: number;
  y: number;
  generic_node: number;
}

export interface LocalGraphicGeometry {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

export const localViewZipFileName = 'Local-views';
export const localViewProjectFileName = 'project.dpj';
export const MAX_VIZ_FILES = 5;
export const timeout = 1200000;
export const FONT_SIZE_SMALL = '8';
export const FONT_SIZE_LARGE = '12';
export const SM800_FONT_SIZE_SMALL = '12';
export const SM800_FONT_SIZE_LARGE = '16';
export const BYTES_PER_POINT = 14; // based on RMT logic per datapoint length in viz file
export const BMP_HEADER_SIZE = 54;
export const BMP_WIDTH = 792;
export const BMP_HEIGHT = -548;
export const LOCAL_GRAPHICS_SESSION_EXPIRY_CODE = 100;
export const SECURITY_ENFORCEMENT_LEVEL_PNUM = '9861';
export const SECURITY_TAB_INDEX = '4';
export const BMP_HEIGHT_SM = 548;
export const BMP_RESERVED_SPACE = 2;
export const BMP_TOTAL_DATAPOINTS_BYTES = 1;
export const BMP_VERSION_BYTES = 4;
export const REMOTE_SM_ACCESS_AREA = 'graphics';
export const REMOTE_SM_LOG_FILENAME = 'List_Directory.log';
