import { Unit, User } from '@danfoss/etui-sm-xml';
import { getArray } from 'utils';
import { exportFilesAsZip } from 'utils/export-files';
import { TFunction } from 'i18next';
import {
  ArchiveFiles,
  HistoryArchiveFile,
  HistoryArchiveFileData,
} from '../components/ConfigurationItemContentList/types/HistoryArchive.types';
import { getHistoryArchiveZipFolderName } from '../utils/get-history-archive-folder-name';
import { fetchHistoryArchiveFileData } from './fetch-history-archive-file-content';

export const downloadHistoryArchive = async ([
  archiveFiles,
  user,
  url,
  unit,
  t,
]: [ArchiveFiles, User, string, Unit, TFunction]): Promise<void> => {
  try {
    if (archiveFiles) {
      const { file } = archiveFiles;
      const historyArchiveFiles: HistoryArchiveFile[] = getArray(file);

      const historyArchiveFileData: HistoryArchiveFileData =
        await fetchHistoryArchiveFileData([historyArchiveFiles, user, url]);

      const historyArchiveZipFolderName: string =
        getHistoryArchiveZipFolderName(unit);

      await exportFilesAsZip(
        historyArchiveFileData.historyArchiveFileNames,
        historyArchiveFileData.historyArchiveFileContents,
        historyArchiveZipFolderName,
      );
    }
  } catch (e) {
    throw new Error(t('t3808'));
  }
};
