import * as React from 'react';
import { Div } from '@danfoss/etui-system-elements';
import {
  Button,
  Form,
  icons,
  Modal,
  PasswordInput,
  SelectInput,
  Spinner,
  TextInput,
} from '@danfoss/etui-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@danfoss/etui-system';
import { Controller, useForm } from 'react-hook-form';
import {
  addSiteToAddressBook,
  editSiteInAddressBook,
  removeSiteFromAddressBook,
  verifyAddressBookPassword,
} from 'utils/addressbook-helpers';
import { AddressBookForm } from 'types';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'pages/LoginPage/utils';
import { decrypt } from 'utils/crypto-helpers';
import { usePasswordPrompt } from '../../hooks';
import * as S from './styles';

type FormErrors = {
  user?: boolean;
  ipAddress?: boolean;
  password?: boolean;
};

const protocolOptions = [
  {
    label: 'HTTP',
    value: 'http:',
  },
  {
    label: 'HTTPS',
    value: 'https:',
  },
];

const findProtocol = (value: string) =>
  protocolOptions.find(option => option.value === value);

export const AddressInfoModal = ({
  isOpen,
  onClose,
  selectedAddress,
  onInfoModalClose,
  addressBookPassword,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<FormErrors>({});
  const clearError = (fieldName: string) =>
    setErrors(prevState => ({ ...prevState, [fieldName]: null }));

  const isCreateMode = !Object.values(selectedAddress).length;
  const controlStyles = {
    root: { mb: `${theme.spacing.xs}px` },
    label: { transform: 'translate(8px, 8px)' },
  };

  const {
    setPromptMessage,
    onPromptResolve,
    showPasswordPrompt,
    closePasswordPrompt,
    setPromptButtonLabel,
  } = usePasswordPrompt();

  const { register, control, handleSubmit, getValues } =
    useForm<AddressBookForm>({
      defaultValues: {
        protocol: selectedAddress.protocol
          ? findProtocol(selectedAddress.protocol.value)
          : protocolOptions[0],
        name: selectedAddress.name,
        ipAddress: selectedAddress.ipAddress,
        user: selectedAddress.user,
        password:
          selectedAddress.password &&
          decrypt(selectedAddress.password, addressBookPassword),
      },
      shouldUnregister: false,
    });

  const { ipAddress, user, password } = getValues();
  const isRequiredInfoMissing = [ipAddress, user, password].some(
    field => !field,
  );

  const validateForm = (data: AddressBookForm): boolean => {
    const errors: FormErrors = {};

    if (!data.user.trim()) errors.user = true;
    if (!data.ipAddress.trim()) errors.ipAddress = true;
    if (!data.password.trim()) errors.password = true;

    const hasErrors = !!Object.values(errors).filter(Boolean).length;
    hasErrors && setErrors(errors);

    return hasErrors;
  };

  const handleOnSubmit = (siteData: AddressBookForm) => {
    setIsLoading(true);
    const hasErrors = validateForm(siteData);

    if (hasErrors) return setIsLoading(false);

    try {
      if (isCreateMode) {
        addSiteToAddressBook(siteData, addressBookPassword);
      } else {
        editSiteInAddressBook(
          selectedAddress.id,
          siteData,
          addressBookPassword,
        );
      }

      showSuccessNotification(theme, t, t(isCreateMode ? 't3468' : 't3476'));
      onInfoModalClose();
      onClose();
    } catch (e) {
      showErrorNotification(theme, t, e);
    } finally {
      setIsLoading(false);
    }
  };

  const removeAddressBookItem = () => {
    const handleAddressBookPwdInput = (password: string) => {
      try {
        verifyAddressBookPassword(password);
        removeSiteFromAddressBook(selectedAddress.id);
        showSuccessNotification(theme, t, t('t3475'));
        closePasswordPrompt();
        onInfoModalClose();
        onClose();
      } catch (e) {
        showErrorNotification(theme, t, e);
      }
    };
    setPromptButtonLabel('t74');
    setPromptMessage('t3489');
    onPromptResolve(handleAddressBookPwdInput);
    showPasswordPrompt();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={<Modal.Header title={t(isCreateMode ? 't3464' : 't3465')} />}
      style={{
        content: {
          maxWidth: '350px',
          minWidth: '350px',
        },
      }}
    >
      <Form
        testId="address-book-item-form"
        onSubmit={handleSubmit(handleOnSubmit)}
        styles={{
          root: {
            mt: `${theme.spacing.md}px`,
            mb: `-${theme.spacing.xs}px`,
          },
        }}
      >
        <Controller
          control={control}
          name="protocol"
          render={({ onBlur, onChange, value }) => (
            <SelectInput
              name="protocol"
              label={t('t3482')}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              options={protocolOptions}
              styles={controlStyles}
            />
          )}
        />
        <TextInput
          autoComplete="name"
          disabled={isLoading}
          // @ts-ignore
          elRef={register()}
          label={t('t76')}
          name="name"
          testId="addrInfo-name-modal-input"
          styles={controlStyles}
          tabIndex={0}
        />
        <TextInput
          autoComplete="ipAddress"
          disabled={isLoading}
          // @ts-ignore
          elRef={register()}
          label={`${t('t3386')} ${t('t3513')}`}
          name="ipAddress"
          testId="addrInfo-ipAddr-modal-input"
          styles={!errors.ipAddress && controlStyles}
          tabIndex={0}
          onChange={() => clearError('ipAddress')}
        />
        {errors.ipAddress && (
          <S.Error>{t('t3268', { field: t('t3386') })}</S.Error>
        )}
        <TextInput
          autoComplete="user"
          disabled={isLoading}
          // @ts-ignore
          elRef={register()}
          label={`${t('t923')} ${t('t3513')}`}
          name="user"
          testId="addrInfo-user-modal-input"
          styles={!errors.user && controlStyles}
          tabIndex={0}
          onChange={() => clearError('user')}
        />
        {errors.user && <S.Error>{t('t3268', { field: t('t71') })}</S.Error>}
        <PasswordInput
          autoComplete="password"
          disabled={isLoading}
          testId="addressInfo-passwordInput"
          // @ts-ignore
          elRef={register()}
          label={`${t('t72')} ${t('t3513')}`}
          name="password"
          styles={!errors.password && controlStyles}
          onChange={() => clearError('password')}
        />
        {errors.password && (
          <S.Error>{t('t3268', { field: t('t72') })}</S.Error>
        )}
        {!isCreateMode && (
          <Button
            color={`${theme.palette.brand.light} !important`}
            disabled={isLoading}
            iconProps={{ glyph: icons.CLOSE }}
            id="removeButton"
            mt={`${theme.spacing.md}px`}
            testId="addressInfo-remove-button"
            onClick={removeAddressBookItem}
            p={0}
            tabIndex={0}
          >
            {t('t3466')}
          </Button>
        )}
        <Div display="flex" mt={`${theme.spacing.md * 2}px`}>
          <Button
            id="cancelButton"
            variant="tertiary"
            block={true}
            tabIndex={0}
            disabled={isLoading}
            testId="addressInfo-close-button"
            onClick={onClose}
          >
            {t('t45')}
          </Button>
          <Button
            id="saveButton"
            value="saveButton"
            type="submit"
            variant="primary"
            block={true}
            tabIndex={0}
            testId="addressInfo-save-button"
            disabled={isLoading || isRequiredInfoMissing}
            ml={`${theme.spacing.xs}px`}
          >
            {isLoading && (
              <Spinner
                size={0}
                isTransparent={true}
                styles={{ root: { pr: theme.spacing.sm } }}
              />
            )}
            {t('t266')}
          </Button>
        </Div>
      </Form>
    </Modal>
  );
};
