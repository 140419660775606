import { ConfigurationListItem } from '@danfoss/etui-sm-xml';
import { TABLE_ADDRESS } from 'pages/ConfigurationPage';
import { EditType } from '../types';
import { getIsEditableByItem } from './get-is-editable-by-item';

enum TokenType {
  STRING = 0,
  FIXED = 1,
  ICON = 2,
  MENU_ICON = 3,
  LIST = 4,
  DYNLIST = 5,
  INT = 6,
  FLOAT = 7,
  BDPT = 8,
  TIME = 9,
  DURATION = 10,
  ONOFF_STR = 11,
  SECRET = 12,
  DATETIME = 13,
  BAD = 14,
  COUNT = 15,
}

enum Pnums {
  COPY_CONTROLLER = 4333,
  STAGING_PATTERN = 1263,
}

function getEditType(
  item: ConfigurationListItem,
  copyControllerSelectionCompleted?: boolean,
  menuId?: string,
): EditType {
  const token = parseInt(item.token, 10);
  const enterfunc = parseInt(item.ef, 10);
  const itemtype = parseInt(item.type, 10);
  const itemeditable = parseInt(item.eda, 10);
  const listtableaddress = parseInt(item.lta, 10);
  const toggle = parseInt(item.toggle, 10);
  const pnum = parseInt(item?.pnum, 10);

  if (
    pnum === Pnums.STAGING_PATTERN &&
    menuId === TABLE_ADDRESS.COMPRESSOR_STAGING_PATTERN
  ) {
    return EditType.StagingPatternStep;
  }
  if (pnum === Pnums.COPY_CONTROLLER && copyControllerSelectionCompleted) {
    return EditType.Browse;
  }
  if (itemeditable === 0 && enterfunc !== 0) {
    if (toggle) {
      return EditType.Toggle;
    }
    return EditType.Action;
  }
  if (enterfunc === 0 && listtableaddress !== 0 && !isNaN(listtableaddress)) {
    return EditType.ModalSubView;
  }
  if (item.s_t === '100') {
    return EditType.Permissions;
  }
  if (token === TokenType.LIST || token === TokenType.DYNLIST) {
    return EditType.Select;
  }
  if (
    token === TokenType.STRING &&
    itemtype === 0 &&
    getIsEditableByItem(item)
  ) {
    return EditType.Text;
  }
  if (
    itemtype === 256 &&
    [TokenType.TIME, TokenType.DURATION].includes(token)
  ) {
    return EditType.Time;
  }
  if (token === TokenType.DATETIME) {
    return EditType.DateTime;
  }
  if (token === TokenType.INT) {
    return EditType.Num;
  }
  if (token === TokenType.FLOAT) {
    return EditType.FloatNum;
  }
  if (token === TokenType.BDPT) {
    return EditType.Address;
  }
  if (token === TokenType.ONOFF_STR) {
    return EditType.CheckList;
  }

  return EditType.None;
}

export { getEditType };
